import { AppContext } from 'context/appContext';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import './index.scss';

type Props = {};

const FeedbackOverlay: React.FC<Props> = ({}: Props) => {
	const {
		feedbackController,
		navController: { enable: enableNavController, disable: disableNavController },
	} = useContext(AppContext);
	const [feedbackElements, setFeedbackElements] = useState<ReactElement[]>([]);
	const removeFeedbackElement = (key: string) =>
		setFeedbackElements((prev) => prev.filter((feedbackElement) => feedbackElement.key !== key));

	const closeWindow = (timeout = 1500) => {
		setTimeout(() => {
			try {
				window.opener?.postMessage({ refreshPage: true }, document.referrer);
			} catch (e) {
				console.error(e);
			}
			window.close();
		}, timeout);
	};

	// Subscribe to feedbackables
	useEffect(() => {
		feedbackController.subscribe({
			name: 'sessionEnd',
			subscription: {
				type: 'card',
				onDismissCallback: (key: string) => {
					closeWindow(100);
				},
				onSubmitCallback: (key: string) => {
					closeWindow();
				},
				onPromptCallback: (element: ReactElement) =>
					setFeedbackElements((prev) => [...prev, element]),
			},
		});
	}, []);

	useEffect(() => {
		if (!feedbackElements.length) enableNavController();
	}, [feedbackElements.length]);

	// TODO: Remove
	(window as any).toggleFeedback = () => {
		feedbackController.toggle();
		return feedbackController.isEnabled();
	};

	return feedbackElements?.length ? (
		<div
			className="feedbackOverlayWrapper"
			onFocus={disableNavController}
			onBlur={enableNavController}
		>
			{feedbackElements[0]}
		</div>
	) : null;
};

export default FeedbackOverlay;
