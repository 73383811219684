import React from 'react';
import './index.scss';

class PropsFromParent {
	id: string;
}

const SessionID: React.FC<PropsFromParent> = ({ id }) => {
	return (
		<div
			className="sessionIdContainer"
			onClick={() => {
				navigator.clipboard.writeText(id);
			}}
		>
			<span>{id}</span>
		</div>
	);
};

export default SessionID;
