import AutoSizeTextarea from 'components/autoSizeTextarea';
import { useEffect, useRef, useState } from 'react';

type Props = {
	defaultValue?: string;
	placeholder?: string;
	disabled?: boolean;
	maxLength?: number;
	style?: React.CSSProperties;
};

export function useAutoSizeTextarea({
	defaultValue,
	placeholder,
	disabled,
	maxLength = 500,
	style,
}: Props) {
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const [value, setValue] = useState<string>(defaultValue!);

	// Updates the height of a <textarea> when the value changes.
	const [measured, setMeasured] = useState<boolean>(false);
	useEffect(() => {
		if (textAreaRef.current) {
			// We need to reset the height momentarily to get the correct scrollHeight for the textarea
			textAreaRef.current.style.height = '0px';
			const scrollHeight = textAreaRef.current.scrollHeight;

			// We then set the height directly, outside of the render loop
			// Trying to set this with state or a ref will product an incorrect value.
			textAreaRef.current.style.height = scrollHeight + 'px';
			if (!measured) setMeasured(true);
		}
	}, [value]);

	useEffect(() => {
		return () => {
			setMeasured(false);
		};
	}, []);

	return {
		textarea: (
			<AutoSizeTextarea
				innerRef={textAreaRef}
				value={value}
				onChange={(event) => setValue(event.target.value)}
				placeholder={placeholder}
				disabled={disabled}
				maxLength={maxLength}
				style={style}
			/>
		),
		value,
		measured,
	};
}
