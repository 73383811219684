// We want to make event listeners non-passive, and to do so have to check
// that browsers support EventListenerOptions in the first place.
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
let passiveSupported = false;

export function makePassiveEventOption(): any {
	try {
		const options = {
			get passive() {
				// This function will be called when the browser
				//   attempts to access the passive property.
				passiveSupported = true;
				return false;
			},
		};

		return options;
	} catch (err) {
		passiveSupported = false;
		return passiveSupported;
	}
}
