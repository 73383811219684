import React from 'react';
import './index.scss';

interface Props {
	value?: boolean;
	onChange: (value: boolean) => any;
	disabled?: boolean;
}

const ToggleButton: React.FC<Props> = ({ value, onChange, disabled }) => {
	return (
		<label className={`switch ${disabled ? 'switch-disabled' : ''}`}>
			<input
				type="checkbox"
				checked={value}
				onChange={(e: any) => onChange(e.target.checked)}
				disabled={disabled}
			/>
			<span className="slider1 round" />
		</label>
	);
};

export default ToggleButton;
