import FeedbackForm from 'components/feedbackForm';
import { AppContext } from 'context/appContext';
import greenGobeLogo from 'images/green-gobe-logo.svg';
import React, { useContext, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';
import { LocalSessionInfo } from 'types';
import './index.scss';

const reduxConnector = connect(
	(state: AppRootState) => ({
		sessionStartDate: state.sessionState.sessionStartDate,
	}),
	{}
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type PropsFromParent = {
	reservation: LocalSessionInfo['reservation'];
	reportUserRating: (rating: number, review: string | null) => void;
};

type ComponentProps = PropsFromRedux & PropsFromParent;

const EndSession: React.FC<ComponentProps> = ({
	sessionStartDate,
	reservation,
	reportUserRating,
}: ComponentProps) => {
	const { feedbackController } = useContext(AppContext);
	const sessionDurationInMs = useMemo(
		() => +new Date() - sessionStartDate.getTime(),
		[sessionStartDate]
	);
	const [showFeedback, setShowFeedback] = useState<boolean>(
		feedbackController.isEnabled() &&
			feedbackController.isFeedbackableEnabled('sessionEnd') &&
			sessionDurationInMs > 5000
	);
	const feedback = feedbackController.getFeedbackable('sessionEnd');

	return (
		<div className="endSessionWrapper">
			<div className="container">
				<img src={greenGobeLogo} alt="" />
				<div className="session">Session ended</div>
				{sessionDurationInMs && Math.floor(sessionDurationInMs / 1000 / 60) ? (
					<div className="minutes">{Math.floor(sessionDurationInMs / 1000 / 60)} minutes</div>
				) : null}
				<div className="endMsg">
					{reservation?.isMyPermanentDevice
						? 'The session ended because of an upcoming reservation.'
						: 'The Session ended because the reservation expired.'}
				</div>
				{showFeedback && feedback?.item ? (
					<div className="feedbackWrapper">
						<FeedbackForm
							feedbackable={feedback.item}
							onDismiss={() => setShowFeedback(false)}
							onSubmit={reportUserRating}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default reduxConnector(EndSession);
