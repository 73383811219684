import { setParameter } from 'actions/setParam';
import { SET_NAV_CAMERA_HEIGHT } from 'actions/types';
import DynamicMedia from 'components/dynamicMedia';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { AppRootState } from 'reducers';
import { CameraConfig, PrimaryCameraState, RobotPrimaryCamera } from 'types';
import './index.scss';

import { PivotState, SessionState } from 'GoBeWebRTC/types';

type PropsFromParent = {
	primaryCameraState: PrimaryCameraState;
	pivotState: PivotState;
	onPlaybackToggle: (value: boolean) => void;
	mediaStream: MediaStream;
	sessionState: SessionState;
	cameraConfigs: Record<RobotPrimaryCamera, CameraConfig>;
	canShowLoadingIndicator: boolean;
	isStreamSynced: boolean;
	isDigitalZoomEnabled: boolean;
	isSuperZoomEnabled: boolean;
	isSuperZoomPivotEnabled: boolean;
	toggleSuperZoom: () => void;
	pivot: (degrees: number) => void;
};

const reduxConnector = connect(
	(state: AppRootState) => ({
		drivingMode: state.sessionState.drivingMode,
		remoteVoiceVolume: state.sessionState.remoteVoiceVolume,
		fullScreenStatus: state.sessionState.fullScreenStatus,
		navCameraHeight: state.sessionState.navCameraHeight,
		robot: state.sessionState.robot,
		cameraCalibrationToolEnabled: state.sessionState.cameraCalibrationToolEnabled,
		cameraCalibrationDeviationStep: state.sessionState.cameraCalibrationDeviationStep,
	}),
	{ setParameter }
);

export const VIDEO_SEPARATOR_HEIGHT = 6 * 2 + 8;

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

/** Video component for playing the video feed from robot's primary camera (zoom or wide cam) */
const RemotePrimaryCamVideo = ({
	mediaStream,
	setParameter,
	remoteVoiceVolume,
	drivingMode,
	sessionState,
	fullScreenStatus,
	navCameraHeight,
	primaryCameraState,
	pivotState,
	onPlaybackToggle,
	robot,
	cameraConfigs,
	canShowLoadingIndicator: showsLoadingIndicator,
	isStreamSynced,
	cameraCalibrationToolEnabled,
	cameraCalibrationDeviationStep,
	isDigitalZoomEnabled,
	isSuperZoomEnabled,
	isSuperZoomPivotEnabled,
	toggleSuperZoom,
	pivot,
}: ComponentProps) => {
	const [isVideoLoading, setIsVideoLoading] = useState(true);

	const isSessionPaused = sessionState === 'Paused';
	const isSessionRetrying = sessionState === 'Retrying';

	const videoRef = useRef<HTMLVideoElement | null>(null);
	const miniViewVideoRef = useRef<HTMLVideoElement | null>(null);
	useEffect(() => {
		if (isStreamSynced && mediaStream) {
			setIsVideoLoading(true);
			videoRef.current!.srcObject = mediaStream;
			miniViewVideoRef.current!.srcObject = mediaStream;
		}
	}, [isStreamSynced, mediaStream]);

	useEffect(() => {
		videoRef.current!.volume = parseInt(remoteVoiceVolume) / 100;
	}, [remoteVoiceVolume, mediaStream]);

	const onCanPlay = () => {
		setIsVideoLoading(false);
		onPlaybackToggle(true);
	};

	// pause/play video depending on paused state of the session
	useEffect(() => {
		if (isVideoLoading) {
			onPlaybackToggle(false);
			return;
		}
	}, [isSessionPaused, isVideoLoading, onPlaybackToggle]);
	useMemo(() => {
		if (isSessionPaused) {
			videoRef.current?.pause();
			miniViewVideoRef.current?.pause();
		} else if (!primaryCameraState.isChangingPrimaryCameraTo) {
			videoRef.current
				?.play()
				.catch((error) => console.error('Unable to play RemotePrimaryCamVideo', error));
			miniViewVideoRef.current
				?.play()
				.catch((error) => console.error('Unable to play RemotePrimaryCamVideo', error));
		}
		return isSessionPaused;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSessionPaused]);

	const resizeNavCamera = (value: number) => {
		setParameter('navCameraHeight', SET_NAV_CAMERA_HEIGHT, value);
	};

	const isVideoLoadingIndicatorVisible = !isSessionRetrying && isVideoLoading;

	return (
		<div className={drivingMode ? 'remoteVideo' : 'remoteVideoFullScreen'}>
			<DynamicMedia
				// todo: Replace with `playing` event instead.
				// todo: The `onCanPlay` event is not always reliable - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/canplay_event
				onCanPlay={onCanPlay}
				autoPlay
				playsInline
				loop
				resizeNavCamera={resizeNavCamera}
				miniViewVideoRef={miniViewVideoRef}
				videoRef={videoRef}
				drivingMode={drivingMode}
				primaryCameraState={primaryCameraState}
				pivotState={pivotState}
				cameraConfigs={cameraConfigs}
				cameraConfig={cameraConfigs[primaryCameraState.currentPrimaryCamera]}
				fullScreenStatus={fullScreenStatus}
				navCameraHeight={navCameraHeight}
				sessionState={sessionState}
				cameraCalibrationToolEnabled={cameraCalibrationToolEnabled}
				cameraCalibrationDeviationStep={cameraCalibrationDeviationStep}
				isDigitalZoomEnabled={isDigitalZoomEnabled}
				isSuperZoomEnabled={isSuperZoomEnabled}
				isSuperZoomPivotEnabled={isSuperZoomPivotEnabled}
				toggleSuperZoom={toggleSuperZoom}
				pivot={pivot}
			/>
			{isVideoLoadingIndicatorVisible && showsLoadingIndicator ? (
				<div className="sessionStartContainer">
					<>
						<div className="sessionStartLoading" />
					</>
				</div>
			) : null}
		</div>
	);
};

export default reduxConnector(RemotePrimaryCamVideo);
