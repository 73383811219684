import { StatMode } from 'GoBeWebRTC/hooks/useStats';
import { ObjectDetectionLibraries } from 'pages/session/videos/objectDetection';
import { ObjectSegmentationLibraries } from 'pages/session/videos/objectSegmentation';
import { useState } from 'react';
import { DragModes } from 'types';

export enum SettingPageSectionHeaders {
	APP_SETTINGS = 'App settings',
	EXPERIMENTAL = 'Experimental',
	ADMIN = 'Admin',
}

export enum SettingPageHeaders {
	APPEARANCE = 'Appearance',
	AUGMENTED_REALITY = 'Augmented reality',
	URL_SHARING = 'URL sharing',
	AUDIOVIDEO = 'Audio/Video',
	FEEDBACK = 'Feedback',
	IMAGE_RECOGNITION = 'Image recognition',
	ZOOM = 'Zoom',
	TOOLS = 'Tools',
	MISC = 'Miscellaneous',
}

export enum SettingTabHeaders {
	GENERAL = 'General',
	OBJECT_DETECTION = 'Detection',
	OBJECT_SEGMENTATION = 'Segmentation',
}

export enum SettingSectionHeaders {
	// SKIN = 'Skin',
	// AURA = 'Aura',
	RESET = 'Reset to default',
	AUGMENTED_REALITY = 'Augmented reality settings',
	URL_SHARING = 'URL sharing settings',
	AUDIOVIDEO = 'Audio/Video Settings',
	FEEDBACKS = 'Feedback settings',
	DETECTION = 'Detection',
	SEGMENTATION = 'Segmentation',
	SUPERZOOM = 'Super zoom',
	MISC = 'Miscellaneous',
}

export enum SettingHeaders {
	// REMOTE_CAM_SKIN = 'Remote camera container skin',
	// NAV_CAM_SKIN = 'Navigation camera container skin',
	// SHOW_AURA = 'Show AURA around remote and navigation cameras',
	RESET = 'Reset',
	AR_ENABLE_NAV_LINES = 'Enable navigation lines',
	URL_SHARING_ENABLED = 'Enable URL sharing',
	FEEDBACK_GENERAL = 'Show Feedback',
	FEEDBACK_SESSION_END = 'Show session feedback',
	FEEDBACK_SWITCH = 'Show switch feedback',
	ENABLE_EXPERIMENTAL = 'Enable experimental',
	DRAG_MODE = 'Select the drag mode',
	OBJECT_DETECTION_LIBRARY = 'Select object detection library',
	ENABLE_OBJECT_DETECTION = 'Enable image recognition',
	ENABLE_OBJECT_DETECTION_FACE_TRACKING = 'Enable image-recognition face tracking',
	OBJECT_SEGMENTATION_LIBRARY = 'Select object segmentation library',
	ENABLE_SELFIE_SEGMENTATION = 'Enable selfie segmentation',
	ENABLE_ADMIN = 'Enable admin',
	ENABLE_CAMERA_CALIBRATION_TOOL = 'Enable camera-calibration tool',
	STAT_MODE = 'Select the stat mode',
	MICROPHONE = 'Microphone',
	SPEAKERS = 'Speakers',
	CAMERA = 'Camera',
	AR_INVERT_BACKWARD_NAV_LINES = 'Invert steering when driving backward',
	NAV_SPEED = 'Nav speed',
	LOCAL_VOICE_VOLUME = 'Local voice volume',
	ENABLE_DIGITAL_ZOOM = 'Enable digital zoom',
	ENABLE_SUPER_ZOOM = 'Enable super zoom',
	MIRROR_PILOT_VIDEO_LOCALLY = 'Mirror pilot video locally',
}

export type Setting = {
	header: SettingHeaders;
	hideHeader?: boolean;
	description: string;
	value: any;
};

export type SettingSection = {
	header: SettingSectionHeaders;
	hideHeader?: boolean;
	description: string;
	children: { [setting: string]: Setting };
};

export type SettingTab = {
	header: SettingTabHeaders;
	description: string;
	children: { [section: string]: SettingSection };
};

export type SettingPage = {
	header: SettingPageHeaders;
	description: string;
	children: { [tab: string]: SettingTab };
};

export type SettingPageSection = {
	header: SettingPageSectionHeaders;
	hideHeader?: boolean;
	description: string;
	children: { [page: string]: SettingPage };
};

export type Settings = {
	[header in SettingPageSectionHeaders]: SettingPageSection;
};

export type SettingsController = {
	getDefaultSetting: (header: SettingHeaders) => any;
	setSettingsId: (id: string) => void;
	settings: { [header in SettingPageSectionHeaders]: SettingPageSection };
	isOpen: boolean;
	toggle: () => void;
	setSettingsValue: (settings: {
		[header in SettingHeaders]?: any;
	}) => void;
	setSettingValue: (header: SettingHeaders, value: any) => void;
	reset: (settingGroup: SettingGroup) => void;
};

export type SettingGroup = SettingPageSection | SettingPage | SettingTab | SettingSection | Setting;

const useSettingsController = () => {
	const [settingsId, setSettingsId] = useState<string | null>();
	const getDefaultSetting: (header: SettingHeaders) => any = (header) => {
		switch (header) {
			// case SettingHeaders.REMOTE_CAM_SKIN:
			// 	return undefined;
			// case SettingHeaders.NAV_CAM_SKIN:
			// 	return undefined;
			// case SettingHeaders.SHOW_AURA:
			// 	return false;
			case SettingHeaders.AR_ENABLE_NAV_LINES:
				return true;
			case SettingHeaders.AR_INVERT_BACKWARD_NAV_LINES:
				return true;
			case SettingHeaders.URL_SHARING_ENABLED:
				return false;
			case SettingHeaders.FEEDBACK_GENERAL:
				return true;
			case SettingHeaders.FEEDBACK_SESSION_END:
				return true;
			case SettingHeaders.FEEDBACK_SWITCH:
				return false;
			case SettingHeaders.DRAG_MODE:
				return DragModes.DISABLED;
			case SettingHeaders.OBJECT_DETECTION_LIBRARY:
				return ObjectDetectionLibraries.COCO_SSD;
			case SettingHeaders.ENABLE_OBJECT_DETECTION:
				return false;
			case SettingHeaders.ENABLE_OBJECT_DETECTION_FACE_TRACKING:
				return false;
			case SettingHeaders.OBJECT_SEGMENTATION_LIBRARY:
				return ObjectSegmentationLibraries.MEDIAPIPE;
			case SettingHeaders.ENABLE_SELFIE_SEGMENTATION:
				return false;
			case SettingHeaders.ENABLE_CAMERA_CALIBRATION_TOOL:
				return false;
			case SettingHeaders.STAT_MODE:
				return StatMode.DEFAULT;
			case SettingHeaders.MICROPHONE:
				return { name: 'Default', value: 'Default' };
			case SettingHeaders.SPEAKERS:
				return { name: 'Default', value: 'Default' };
			case SettingHeaders.CAMERA:
				return undefined;
			case SettingHeaders.NAV_SPEED:
				return 40;
			case SettingHeaders.LOCAL_VOICE_VOLUME:
				return 25;
			case SettingHeaders.ENABLE_DIGITAL_ZOOM:
				return true;
			case SettingHeaders.ENABLE_SUPER_ZOOM:
				return false;
			case SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY:
				return false;
		}
	};
	const defaultSettings: {
		[header in SettingPageSectionHeaders]: SettingPageSection;
	} = {
		[SettingPageSectionHeaders.APP_SETTINGS]: {
			header: SettingPageSectionHeaders.APP_SETTINGS,
			description: '',
			children: {
				// [SettingPageHeaders.APPEARANCE]: {
				// 	header: SettingPageHeaders.APPEARANCE,
				// 	description: '',
				// 	children: {
				// 		[SettingTabHeaders.GENERAL]: {
				// 			header: SettingTabHeaders.GENERAL,
				// 			description: '',
				// 			children: {
				// 				[SettingSectionHeaders.SKIN]: {
				// 					header: SettingSectionHeaders.SKIN,
				// 					description: '',
				// 					children: {
				// 						[SettingHeaders.REMOTE_CAM_SKIN]: {
				// 							header: SettingHeaders.REMOTE_CAM_SKIN,
				// 							description: '',
				// 							value: getDefaultSetting(SettingHeaders.REMOTE_CAM_SKIN),
				// 						},
				// 						[SettingHeaders.NAV_CAM_SKIN]: {
				// 							header: SettingHeaders.NAV_CAM_SKIN,
				// 							description: '',
				// 							value: getDefaultSetting(SettingHeaders.NAV_CAM_SKIN),
				// 						},
				// 					},
				// 				},
				// 				[SettingSectionHeaders.AURA]: {
				// 					header: SettingSectionHeaders.AURA,
				// 					description: '',
				// 					children: {
				// 						[SettingHeaders.SHOW_AURA]: {
				// 							header: SettingHeaders.SHOW_AURA,
				// 							description: '',
				// 							value: getDefaultSetting(SettingHeaders.SHOW_AURA),
				// 						},
				// 					},
				// 				},
				// 				[SettingSectionHeaders.RESET]: {
				// 					header: SettingSectionHeaders.RESET,
				// 					description: '',
				// 					children: {
				// 						[SettingHeaders.RESET]: {
				// 							header: SettingHeaders.RESET,
				// 							hideHeader: true,
				// 							description: '',
				// 							value: null,
				// 						},
				// 					},
				// 				},
				// 			},
				// 		},
				// 	},
				// },
				[SettingPageHeaders.AUGMENTED_REALITY]: {
					header: SettingPageHeaders.AUGMENTED_REALITY,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.AUGMENTED_REALITY]: {
									header: SettingSectionHeaders.AUGMENTED_REALITY,
									description: '',
									children: {
										[SettingHeaders.AR_ENABLE_NAV_LINES]: {
											header: SettingHeaders.AR_ENABLE_NAV_LINES,
											description: '',
											value: getDefaultSetting(SettingHeaders.AR_ENABLE_NAV_LINES),
										},
										[SettingHeaders.AR_INVERT_BACKWARD_NAV_LINES]: {
											header: SettingHeaders.AR_INVERT_BACKWARD_NAV_LINES,
											description: '',
											value: getDefaultSetting(SettingHeaders.AR_INVERT_BACKWARD_NAV_LINES),
										},
										[SettingHeaders.ENABLE_DIGITAL_ZOOM]: {
											header: SettingHeaders.ENABLE_DIGITAL_ZOOM,
											description: '',
											value: getDefaultSetting(SettingHeaders.ENABLE_DIGITAL_ZOOM),
										},
									},
								},
							},
						},
					},
				},
				[SettingPageHeaders.AUDIOVIDEO]: {
					header: SettingPageHeaders.AUDIOVIDEO,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.AUDIOVIDEO]: {
									header: SettingSectionHeaders.AUDIOVIDEO,
									description: '',
									children: {
										[SettingHeaders.MICROPHONE]: {
											header: SettingHeaders.MICROPHONE,
											description: '',
											value: getDefaultSetting(SettingHeaders.MICROPHONE),
										},
										[SettingHeaders.SPEAKERS]: {
											header: SettingHeaders.SPEAKERS,
											description: '',
											value: getDefaultSetting(SettingHeaders.SPEAKERS),
										},
										[SettingHeaders.CAMERA]: {
											header: SettingHeaders.CAMERA,
											description: '',
											value: getDefaultSetting(SettingHeaders.CAMERA),
										},
										[SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY]: {
											header: SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY,
											description:
												'This will make the pilot card 1:1 identical with the GoBe screen',
											value: getDefaultSetting(SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY),
										},
									},
								},
							},
						},
					},
				},
				[SettingPageHeaders.FEEDBACK]: {
					header: SettingPageHeaders.FEEDBACK,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.FEEDBACKS]: {
									header: SettingSectionHeaders.FEEDBACKS,
									description: '',
									children: {
										[SettingHeaders.FEEDBACK_GENERAL]: {
											header: SettingHeaders.FEEDBACK_GENERAL,
											description: '',
											value: getDefaultSetting(SettingHeaders.FEEDBACK_GENERAL),
										},
										[SettingHeaders.FEEDBACK_SESSION_END]: {
											header: SettingHeaders.FEEDBACK_SESSION_END,
											description: '',
											value: getDefaultSetting(SettingHeaders.FEEDBACK_SESSION_END),
										},
										[SettingHeaders.FEEDBACK_SWITCH]: {
											header: SettingHeaders.FEEDBACK_SWITCH,
											description: '',
											value: getDefaultSetting(SettingHeaders.FEEDBACK_SWITCH),
										},
									},
								},
								[SettingSectionHeaders.RESET]: {
									header: SettingSectionHeaders.RESET,
									description: '',
									children: {
										[SettingHeaders.RESET]: {
											header: SettingHeaders.RESET,
											hideHeader: true,
											description: '',
											value: null,
										},
									},
								},
							},
						},
					},
				},
			},
		},
		[SettingPageSectionHeaders.EXPERIMENTAL]: {
			header: SettingPageSectionHeaders.EXPERIMENTAL,
			hideHeader: true,
			description: '',
			children: {
				[SettingPageHeaders.URL_SHARING]: {
					header: SettingPageHeaders.URL_SHARING,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.URL_SHARING]: {
									header: SettingSectionHeaders.URL_SHARING,
									description: '',
									children: {
										[SettingHeaders.URL_SHARING_ENABLED]: {
											header: SettingHeaders.URL_SHARING_ENABLED,
											description: '',
											value: getDefaultSetting(SettingHeaders.URL_SHARING_ENABLED),
										},
									},
								},
							},
						},
					},
				},
				[SettingPageHeaders.APPEARANCE]: {
					header: SettingPageHeaders.APPEARANCE,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.MISC]: {
									header: SettingSectionHeaders.MISC,
									description: '',
									children: {
										[SettingHeaders.DRAG_MODE]: {
											header: SettingHeaders.DRAG_MODE,
											description: '',
											value: getDefaultSetting(SettingHeaders.DRAG_MODE),
										},
									},
								},
							},
						},
					},
				},
				[SettingPageHeaders.ZOOM]: {
					header: SettingPageHeaders.ZOOM,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.SUPERZOOM]: {
									header: SettingSectionHeaders.SUPERZOOM,
									description: '',
									children: {
										[SettingHeaders.ENABLE_SUPER_ZOOM]: {
											header: SettingHeaders.ENABLE_SUPER_ZOOM,
											description: '',
											value: getDefaultSetting(SettingHeaders.ENABLE_SUPER_ZOOM),
										},
									},
								},
							},
						},
					},
				},
				[SettingPageHeaders.IMAGE_RECOGNITION]: {
					header: SettingPageHeaders.IMAGE_RECOGNITION,
					description: '',
					children: {
						[SettingTabHeaders.OBJECT_DETECTION]: {
							header: SettingTabHeaders.OBJECT_DETECTION,
							description: '',
							children: {
								[SettingSectionHeaders.DETECTION]: {
									header: SettingSectionHeaders.DETECTION,
									description: '',
									children: {
										[SettingHeaders.OBJECT_DETECTION_LIBRARY]: {
											header: SettingHeaders.OBJECT_DETECTION_LIBRARY,
											description: '',
											value: getDefaultSetting(SettingHeaders.OBJECT_DETECTION_LIBRARY),
										},
										[SettingHeaders.ENABLE_OBJECT_DETECTION]: {
											header: SettingHeaders.ENABLE_OBJECT_DETECTION,
											description: '',
											value: getDefaultSetting(SettingHeaders.ENABLE_OBJECT_DETECTION),
										},
										[SettingHeaders.ENABLE_OBJECT_DETECTION_FACE_TRACKING]: {
											header: SettingHeaders.ENABLE_OBJECT_DETECTION_FACE_TRACKING,
											description: '',
											value: getDefaultSetting(
												SettingHeaders.ENABLE_OBJECT_DETECTION_FACE_TRACKING
											),
										},
									},
								},
								[SettingSectionHeaders.RESET]: {
									header: SettingSectionHeaders.RESET,
									description: '',
									children: {
										[SettingHeaders.RESET]: {
											header: SettingHeaders.RESET,
											hideHeader: true,
											description: '',
											value: null,
										},
									},
								},
							},
						},
						[SettingTabHeaders.OBJECT_SEGMENTATION]: {
							header: SettingTabHeaders.OBJECT_SEGMENTATION,
							description: '',
							children: {
								[SettingSectionHeaders.SEGMENTATION]: {
									header: SettingSectionHeaders.SEGMENTATION,
									description: '',
									children: {
										[SettingHeaders.OBJECT_SEGMENTATION_LIBRARY]: {
											header: SettingHeaders.OBJECT_SEGMENTATION_LIBRARY,
											description: '',
											value: getDefaultSetting(SettingHeaders.OBJECT_SEGMENTATION_LIBRARY),
										},
										[SettingHeaders.ENABLE_SELFIE_SEGMENTATION]: {
											header: SettingHeaders.ENABLE_SELFIE_SEGMENTATION,
											description: '',
											value: getDefaultSetting(SettingHeaders.ENABLE_SELFIE_SEGMENTATION),
										},
									},
								},
								[SettingSectionHeaders.RESET]: {
									header: SettingSectionHeaders.RESET,
									description: '',
									children: {
										[SettingHeaders.RESET]: {
											header: SettingHeaders.RESET,
											hideHeader: true,
											description: '',
											value: null,
										},
									},
								},
							},
						},
					},
				},
			},
		},
		[SettingPageSectionHeaders.ADMIN]: {
			header: SettingPageSectionHeaders.ADMIN,
			hideHeader: true,
			description: '',
			children: {
				[SettingPageHeaders.TOOLS]: {
					header: SettingPageHeaders.TOOLS,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.MISC]: {
									header: SettingSectionHeaders.MISC,
									description: '',
									children: {
										[SettingHeaders.ENABLE_CAMERA_CALIBRATION_TOOL]: {
											header: SettingHeaders.ENABLE_CAMERA_CALIBRATION_TOOL,
											description: '',
											value: getDefaultSetting(SettingHeaders.ENABLE_CAMERA_CALIBRATION_TOOL),
										},
										[SettingHeaders.STAT_MODE]: {
											header: SettingHeaders.STAT_MODE,
											description: '',
											value: getDefaultSetting(SettingHeaders.STAT_MODE),
										},
									},
								},
							},
						},
					},
				},
				[SettingPageHeaders.MISC]: {
					header: SettingPageHeaders.MISC,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.MISC]: {
									header: SettingSectionHeaders.MISC,
									hideHeader: true,
									description: '',
									children: {
										[SettingHeaders.NAV_SPEED]: {
											header: SettingHeaders.NAV_SPEED,
											hideHeader: true,
											description: '',
											value: getDefaultSetting(SettingHeaders.NAV_SPEED),
										},
										[SettingHeaders.LOCAL_VOICE_VOLUME]: {
											header: SettingHeaders.LOCAL_VOICE_VOLUME,
											hideHeader: true,
											description: '',
											value: getDefaultSetting(SettingHeaders.LOCAL_VOICE_VOLUME),
										},
									},
								},
							},
						},
					},
				},
			},
		},
	};
	const [settings, setSettings] = useState<Settings>(defaultSettings);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	// Sets the setting value
	const setSettingValue = (header: SettingHeaders, value: any) => {
		setSettings((prevSettings: any) => {
			// find setting
			let found: boolean = false;
			for (let [pageSectionHeader] of Object.entries(prevSettings)) {
				for (let [pageHeader] of Object.entries(prevSettings[pageSectionHeader].children)) {
					for (let [tabHeader] of Object.entries(
						prevSettings[pageSectionHeader].children[pageHeader].children
					)) {
						for (let [sectionHeader] of Object.entries(
							prevSettings[pageSectionHeader].children[pageHeader].children[tabHeader].children
						)) {
							for (let [settingHeader] of Object.entries(
								prevSettings[pageSectionHeader].children[pageHeader].children[tabHeader].children[
									sectionHeader
								].children
							)) {
								if (settingHeader === header) {
									prevSettings[pageSectionHeader].children[pageHeader].children[tabHeader].children[
										sectionHeader
									].children[settingHeader].value = value;
									if (settingsId) {
										if (value === undefined) {
											// Remove the setting
											const settingValue = JSON.parse(localStorage.getItem(settingsId)!);
											delete settingValue[settingHeader];
											localStorage.setItem(settingsId, JSON.stringify(settingValue));
										} else {
											// Update with new value
											localStorage.setItem(
												settingsId,
												JSON.stringify({
													...JSON.parse(localStorage.getItem(settingsId)!),
													[settingHeader]: value,
												})
											);
										}
									}
									found = true;
								}
								if (found) break;
							}
							if (found) break;
						}
						if (found) break;
					}
					if (found) break;
				}
				if (found) break;
			}
			return { ...prevSettings };
		});
	};

	// Sets settings value
	const setSettingsValue: (settings: {
		[header in SettingHeaders]?: any;
	}) => void = (settings) =>
		settings &&
		Object.entries(settings).forEach(([header, value]) =>
			setSettingValue(header as SettingHeaders, value)
		);

	// Toggle to show the setting page
	const toggle = () => {
		setIsOpen((prev) => !prev);
	};

	/**
	 * Resets the settings
	 */
	const reset = (settingGroup: SettingGroup) => {
		if ('value' in settingGroup)
			setSettingValue(settingGroup.header, getDefaultSetting(settingGroup.header));
		else Object.values(settingGroup.children).forEach((child) => reset(child));
	};

	return {
		setSettingsId,
		setSettingsValue,
		settings,
		isOpen,
		toggle,
		setSettingValue,
		reset,
	} as SettingsController;
};

export default useSettingsController;
