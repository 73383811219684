import { combineReducers } from 'redux';
import sessionReducers from './sessionReducers';
import hardwareReducer from './hardwareReducer';

import { useSelector, TypedUseSelectorHook } from 'react-redux';

// This will be the structure of the entire Store in redux
// export type AppRootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
	sessionState: sessionReducers,
	hardwareState: hardwareReducer,
});

export type AppRootState = ReturnType<typeof rootReducer>;

/** A typed variant of the useSelector redux hook, that knows the type of this application root state.
 *
 * This is only an effort to prevent repetitively specifying selector types
 */
export const useTypedSelector: TypedUseSelectorHook<AppRootState> = useSelector;

export default rootReducer;
