import { SET_IS_NAVIGATING } from 'actions/types';
import { AppContext } from 'context/appContext';
import useDrivingImpairment from 'hooks/useDrivingImpairment';
import { NavController } from 'hooks/useNavController';
import { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { RtpReceiverID } from 'types';

export type SessionNavController = NavController & {
	isNavigationInProgress: boolean;
	isDrivingImpaired: boolean;
	penalty: number;
};

const useSessionNavController: (props: {
	speed: number | string;
	isPeerConnectionPaused: boolean;
	rtpReceivers: Partial<Record<RtpReceiverID, RTCRtpReceiver>>;
	datachannel?: RTCDataChannel;
	/** Pass in True, if the video can be seen by the user, and there are no overlays, modals etc, obscuring the view */
	isVideoVisible: boolean;
	setParameter: (param: string, type: string, payload?: any) => void;
}) => SessionNavController = ({
	speed,
	isPeerConnectionPaused,
	rtpReceivers,
	datachannel,
	isVideoVisible,
	setParameter,
}) => {
	// a ref, because we dont ever change the created instance
	const { navController } = useContext(AppContext);

	const isNavigationInProgressRef = useRef<boolean>(false);
	const [isNavigationInProgress, setIsNavigationInProgress] = useState(false);
	useLayoutEffect(() => {
		const onNavigationStarted = () => {
			setParameter('isNavigating', SET_IS_NAVIGATING, true);
			if (!isNavigationInProgressRef.current) {
				isNavigationInProgressRef.current = true;
				setIsNavigationInProgress(true);
			}
		};
		const onNavigationStopped = () => {
			setParameter('isNavigating', SET_IS_NAVIGATING, false);
			if (isNavigationInProgressRef.current) {
				isNavigationInProgressRef.current = false;
				setIsNavigationInProgress(false);
			}
		};
		navController.addEventListener('navigation-started', onNavigationStarted);
		navController.addEventListener('navigation-stopped', onNavigationStopped);
		return () => {
			navController.removeEventListener('navigation-started', onNavigationStarted);
			navController.removeEventListener('navigation-stopped', onNavigationStopped);
		};
	}, []);

	// Deactivate the navController when the implementing component
	// 	is about to be unmounted.
	useLayoutEffect(() => {
		return () => {
			// console.debug(`useImperativeNavController -> unmounted`);
			navController.disable();
		};
	}, []);

	/** track base speed at which the navController will operate */
	useMemo(() => {
		navController.onSpeedChanged(Number.parseInt(speed.toString()));
		return speed;
	}, [speed]);

	/** Track peer connection's paused state, and enable/disable navController */
	useMemo(() => {
		const shouldDisableNavigation = isPeerConnectionPaused || !isVideoVisible;
		if (shouldDisableNavigation) {
			navController.disable();
		} else {
			navController.enable();
		}
		return shouldDisableNavigation;
	}, [isPeerConnectionPaused, isVideoVisible]);

	/** Assign the data datachannel to the nav controller */
	useMemo(() => {
		if (datachannel) navController.onDataChannel(datachannel);
		return datachannel;
	}, [datachannel]);

	// Driving impairement handling
	const { penalty, onRtpReceiver } = useDrivingImpairment(15, 10);
	useLayoutEffect(() => navController.onPenalty(penalty), [penalty]);

	useLayoutEffect(() => {
		Object.entries(rtpReceivers).forEach(([key, receiver]) => {
			if (receiver) {
				onRtpReceiver(key as RtpReceiverID, receiver);
			}
		});
	}, [onRtpReceiver, rtpReceivers]);

	return {
		...navController,
		isNavigationInProgress,
		/** True if the driving-impaired indicator should be shown */
		isDrivingImpaired: isNavigationInProgress && penalty > 0.4,
		penalty,
	} as SessionNavController;
};

export default useSessionNavController;
