import { FeedbackController } from 'hooks/useFeedbackController';
import { NavController } from 'hooks/useNavController';
import { SettingsController } from 'hooks/useSettingsController';
import { createContext } from 'react';

type AppContextValue = {
	navController: NavController;
	feedbackController: FeedbackController;
	settingsController: SettingsController;
};

export const AppContext = createContext({} as AppContextValue);
