import { Rating } from 'components/starRating/components/Rating';
import { AppContext } from 'context/appContext';
import { useAutoSizeTextarea } from 'hooks/useAutoSizeTextarea';
import { Feedbackable } from 'hooks/useFeedbackController';
import React, { useContext, useState } from 'react';
import { feedbackNameToSettingHeader } from 'utils/feedbackNameToSettingHeader';
import './index.scss';

export type FeedbackFormProps = {
	feedbackable: Feedbackable;
	onSubmit?: (rating: number, review: string | null) => void;
	onDismiss?: () => void;
};

function FeedbackForm({
	feedbackable: {
		name,
		title,
		ratable = true,
		reviewable = true,
		postSubmitMessage = 'Thank you for your feedback!',
		hideDisable = false,
	},
	onSubmit,
	onDismiss,
}: FeedbackFormProps) {
	// Rating scale
	const tooltipArray = ['Terrible', 'Bad', 'Average', 'Great', 'Perfect'];
	const fillColorArray = ['#f04747', '#bd693d', '#8a8a32', '#56ac28', '#23cd1d'];
	// Currently hovered rating
	const [hoveredRating, setHoveredRating] = useState<number | null>(null);
	// Rating from 1 to 5, null for unset
	const [rating, setRating] = useState<number | null>(null);
	// True if feedback is being submitted
	const [submitting, setSubmitting] = useState<boolean>(false);
	// True if feedback is already submitted
	const [submitted, setSubmitted] = useState<boolean>(false);
	// Use custom auto size text area
	const {
		textarea,
		value: review,
		measured,
	} = useAutoSizeTextarea({
		placeholder: 'Write a review message',
		disabled: submitting || submitted || null === rating,
		style: {
			maxHeight: '40vh',
		},
	});

	// Toggle method for specific feedbackable from feedback controller
	const {
		settingsController,
		feedbackController: { toggleFeedbackable },
	} = useContext(AppContext);

	// Catch Rating value
	const handleRating = (rate: number) => {
		setRating(rate);
	};

	// Handle submit
	const handleSubmit: (e: React.FormEvent) => void = (e) => {
		e.preventDefault();
		setSubmitting(true);
		if (onSubmit) onSubmit(rating!, review ? (review === '' ? null : review.trim()) : null);
		setSubmitted(true);
		setSubmitting(false);
	};

	// Handle dismiss
	const dismiss = () => {
		if (onDismiss) onDismiss();
	};

	return (
		<form
			onSubmit={handleSubmit}
			className={`feedbackFormWrapper ${
				reviewable && !measured ? 'feedbackFormWrapperHidden' : ''
			}`}
		>
			<div className="feedbackFormButtonWrapper">
				<div>
					{onDismiss ? (
						<input
							className="feedbackFormDismissButton"
							type="button"
							value="Dismiss"
							onClick={dismiss}
							disabled={submitting && !submitted}
						/>
					) : null}
					{!submitted ? (
						<input
							className="feedbackFormSubmitButton"
							type="submit"
							disabled={submitting || submitted || null === rating}
						/>
					) : null}
				</div>
				{!hideDisable && onDismiss && !submitted ? (
					<input
						className="feedbackFormTurnOffButton"
						disabled={submitting || submitted}
						type="button"
						value="Don't show this again"
						onClick={() => {
							const settingHeader = feedbackNameToSettingHeader(name);
							if (settingHeader) settingsController.setSettingValue(settingHeader, false);
							else toggleFeedbackable(name);
							dismiss();
						}}
					/>
				) : null}
			</div>
			{submitted ? <span className="feedbackFormTitle">{postSubmitMessage}</span> : null}
			{reviewable ? <div className="feedbackFormMessageWrapper">{textarea}</div> : null}
			{ratable ? (
				<div className="feedbackFormRatingWrapper">
					<Rating
						onPointerMove={(value: any) => setHoveredRating(value)}
						onPointerLeave={() => setHoveredRating(null)}
						onClick={handleRating}
						transition
						size={40}
						showTooltip
						tooltipArray={tooltipArray}
						fillColorArray={fillColorArray}
						tooltipClassName="feedbackFormRatingTooltip"
						tooltipStyle={{
							color:
								hoveredRating || rating ? fillColorArray[(hoveredRating! ?? rating) - 1] : 'white',
						}}
						readonly={submitting || submitted}
					/>
					<div className="feedbackFormRatingScale">
						<span>{tooltipArray[0]}</span>
						<span>{tooltipArray[tooltipArray.length - 1]}</span>
					</div>
				</div>
			) : null}
			<span className="feedbackFormTitle">{title}</span>
		</form>
	);
}

export default FeedbackForm;
