import React from 'react';
import './index.scss';

const HelpCenterAnchor: React.FC = () => {
	return (
		<a
			className="urlLink"
			href="https://www.gobe-robots.com/help"
			target="_blank"
			rel="noopener noreferrer"
		>
			Help Center.
		</a>
	);
};

export default HelpCenterAnchor;
