function sessionSoftRetryingFilter(isSessionSoftRetrying: boolean) {
	const grayFilter = `grayscale(${isSessionSoftRetrying ? '100' : '0'}%)`;
	return {
		WebkitFilter: grayFilter,
		filter: `${grayFilter} ${isSessionSoftRetrying ? 'saturate(4)' : ''}`,
		transition: 'filter 0.5s ease-out',
	};
}

export { sessionSoftRetryingFilter };
