import { useEffect } from 'react';

import { ReactZoomPanPinchContextState } from '../models';
import { getState } from '../utils';
import { useTransformContext } from './use-transform-context';

export const useTransformInit = (
	callback: (ref: ReactZoomPanPinchContextState) => void | (() => void)
): void => {
	const libraryContext: any = useTransformContext();

	useEffect(() => {
		let unmountCallback: void | (() => void);
		let unmount: void | (() => void);
		if (libraryContext.contentComponent && libraryContext.wrapperComponent) {
			unmountCallback = callback(getState(libraryContext));
		} else {
			unmount = libraryContext.onInit((ref: any) => {
				unmountCallback = callback(getState(ref.instance));
			});
		}
		return () => {
			unmount?.();
			unmountCallback?.();
		};
	}, []);
};
