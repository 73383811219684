import genericAlertIcon from 'images/alert.svg';
import flashOff from 'images/flash-off.svg';
import chargingIcon from 'images/flash.svg';
import greenflash from 'images/green-flash2.svg';
import greyFlash from 'images/grey-flash.svg';
import ParkedIcon from 'images/Rightsymbolgreenback.svg';
import whiteCloseChevronIcon from 'images/white-close-chevron.svg';
import React, { useEffect, useState } from 'react';
import {
	AutoDockingController,
	AutoDockingStatus,
} from '../../navigation/autoDocking/useAutoDockingController';
import './index.scss';

import { setParameter } from 'actions/setParam';
import HelpCenterAnchor from 'components/helpCenterAnchor';
import Snackbar from 'components/snackbar';
import chargingInProgressIcon from 'images/charging.svg';
import dockingStagePositioningIcon from 'images/docking-positioning.svg';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';

const reduxConnector = connect(
	(state: AppRootState) => ({
		isAutoParking: state.sessionState.isAutoParking,
		robotStatus: state.sessionState.robotStatus,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type PropsFromParent = {
	status: AutoDockingStatus;
};
type ComponentProps = PropsFromRedux & PropsFromParent;

type IConfig = {
	title: string;
	label: string;
	icon: string;
	actionRequired: React.ReactNode;
	iconDisplayRule: 'Both' | 'Minimized' | 'Regular';
};

function getConfig(
	{ stage, state, customData }: AutoDockingController['status'],
	isAutoParking: boolean
) {
	const actionRequiredToStop = <>Press any key to stop.</>;

	const failedActionRequired = (
		<>
			<span>Make sure that the charging dock is powered on. </span>
			If the problem persists, visit to the <HelpCenterAnchor />
		</>
	);
	const DockingStatusConfig: Record<
		AutoDockingController['status']['stage'],
		Partial<IConfig & Record<AutoDockingController['status']['state'], Partial<IConfig>>> | null
	> = {
		DETECTING: {
			title: 'Docking',
			actionRequired: actionRequiredToStop,
			IN_PROGRESS: {
				label: 'Detecting the dock...',
			},
			SUCCESS: {
				label: 'Charging Dock detected',
				actionRequired: (
					<>
						Press <img className="textIcon" src={chargingIcon} alt="" />{' '}
						<span className="textBold">to AutoPark</span>
					</>
				),
			},
			FAILED: {
				label: 'Charging Dock not detected',
				icon: flashOff,
				actionRequired: <>Drive closer to the Charging Dock to park the robot.</>,
			},
			icon: chargingIcon,
			iconDisplayRule: 'Minimized',
		},
		STARTING: {
			title: 'Starting',
			icon: dockingStagePositioningIcon,
			IN_PROGRESS: {
				label: 'Parking...',
				icon: greenflash,
				actionRequired: actionRequiredToStop,
			},
			SUCCESS: {
				label: 'Parking...',
				icon: greenflash,
				actionRequired: actionRequiredToStop,
			},
			FAILED: {
				label: 'Not charging',
				icon: flashOff,
				actionRequired: failedActionRequired,
			},
		},
		DOCKING: {
			title: 'Docking',
			icon: dockingStagePositioningIcon,
			STARTED: {
				label: 'Parking...',
				icon: greenflash,
				actionRequired: actionRequiredToStop,
			},
			IN_PROGRESS: {
				label: 'Parking...',
				icon: greenflash,
				actionRequired: actionRequiredToStop,
			},
			SUCCESS: {
				label: 'Parked',
				icon: ParkedIcon,
				actionRequired: <>Awaiting charging ...</>,
			},
			FAILED: {
				label: (() => {
					switch (customData) {
						case 'STOPPED':
							return 'Parking stopped';
						case 'BLOCKED':
							return 'Parking stopped';
						default:
							return 'Not charging';
					}
				})(),
				icon: (() => {
					switch (customData) {
						case 'STOPPED':
							return greyFlash;
						case 'BLOCKED':
							return flashOff;
						default:
							return genericAlertIcon;
					}
				})(),
				actionRequired: (() => {
					switch (customData) {
						case 'STOPPED':
							return <>The robot will not charge.</>;
						case 'BLOCKED':
							return (
								<>
									Detected an obstacle near the robot. Make sure the robot can maneuver freely in
									front of the Charging Dock.
								</>
							);
						default:
							return failedActionRequired;
					}
				})(),
			},
			RECOVERY: {
				label: (() => {
					switch (Number(customData)) {
						case 1:
							return 'One last try...';
						default:
							return 'Still parking...';
					}
				})(),
				icon: greenflash,
				actionRequired: actionRequiredToStop,
			},
		},
		STOPPING: null,
	};
	const config = DockingStatusConfig[stage];
	if (config === null) return config;

	return {
		title: config[state]?.title ?? config.title,
		label: config[state]?.label ?? config.label,
		icon: config[state]?.icon ?? config.icon,
		actionRequired: config[state]?.actionRequired ?? config.actionRequired,
	} as IConfig;
}

function AutoDockingStatusIndicator({ status, isAutoParking, robotStatus }: ComponentProps) {
	const [config, setConfig] = useState(getConfig(status, isAutoParking));
	const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
	useEffect(() => {
		setConfig(getConfig(status, isAutoParking));
	}, [status, isAutoParking]);
	if (!timeoutId && status.stage === 'DOCKING' && status.state === 'SUCCESS')
		setTimeoutId(
			setTimeout(() => {
				setConfig({
					label: 'Charging',
					icon: chargingInProgressIcon,
					actionRequired: <>Battery level {Math.floor(Number(robotStatus?.battery.level))}%</>,
				} as IConfig);
				setTimeoutId(undefined);
			}, 2000)
		);
	return !(status.stage === 'DETECTING' && status.state === 'FAILED' && !status.customData) &&
		config ? (
		<Snackbar
			title={config.label}
			icon={config.icon}
			iconDisplayRule={config.iconDisplayRule}
			actionRequired={config.actionRequired}
			iconWrapper={whiteCloseChevronIcon}
		/>
	) : null;
}

export default reduxConnector(AutoDockingStatusIndicator);
