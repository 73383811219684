export class MediaDeviceNotFoundError extends Error {
	constructor(public readonly deviceKind: 'camera' | 'microphone' | 'speakers') {
		super(`No connected ${deviceKind} was found`);
	}
}

export class MediaPermissionError extends Error {
	constructor(public readonly deviceKind: 'camera' | 'microphone' | 'speakers') {
		super(`Permission denied. Cannot access ${deviceKind}.`);
	}
}
