import './index.scss';

type Props = {
	size?: 'small' | 'medium' | 'large';
};

const BetaLabel = ({ size = 'medium' }: Props) => {
	// TODO: implement sizing
	return (
		<div className="chip">
			<p className="chip-label">BETA</p>
		</div>
	);
};

export default BetaLabel;
