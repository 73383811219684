import FeedbackCard from 'components/feedbackCard';
import FeedbackForm from 'components/feedbackForm';
import { ReactElement, useMemo, useRef } from 'react';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
	SettingsController,
} from './useSettingsController';

export type FeedbackController = Pick<
	_FeedbackController,
	| 'feedbackables'
	| 'getFeedbackable'
	| 'isEnabled'
	| 'prompt'
	| 'toggle'
	| 'toggleFeedbackable'
	| 'subscribe'
	| 'isFeedbackableEnabled'
>;

export type Feedbackable = {
	name: string;
	title: string;
	ratable: boolean;
	reviewable: boolean;
	hideDisable?: boolean;
	timeout?: number;
	initialDelay?: number;
	postSubmitMessage?: string;
	disabled?: boolean;
	muteSound?: boolean;
};

export type FeedbackableType = 'form' | 'card';

export type FeedbackableSubscription = {
	type: FeedbackableType;
	onPromptCallback: (element: ReactElement) => any;
	onDismissCallback: (key: string) => any;
	onSubmitCallback: (key: string, rating: number, review: string | null) => void;
};

export type FeedbackableSubscribeEvent = {
	name: string;
	subscription: FeedbackableSubscription;
};

class _FeedbackController {
	/** Disabled state */
	private disabled: boolean = false;
	/** Subscribers for prompt event */
	private subscriptions: { [name: string]: FeedbackableSubscription[] } = [] as any;

	/**
	 * Gets a feedbackable matching the search criteria
	 * @param name name criteria
	 * @returns index and item
	 */
	public getFeedbackable: (name: string) => { index: number; item: Feedbackable | undefined } = (
		name
	) => {
		const index = this.feedbackables.findIndex((item) => item.name === name);
		return {
			index,
			item: index < 0 ? undefined : this.feedbackables[index],
		};
	};

	/**
	 * Calls all callbacks tied to the feedbackable
	 * @param name Name of feedbackable
	 * @param feedbackable
	 */
	private callbackSubscriptions: (
		name: string,
		feedbackable: Feedbackable,
		onSubmitCallback?: (rating: number, review: string | null) => void
	) => void = (name, feedbackable, onSubmitCallback) => {
		this.subscriptions[name]?.forEach((subscription) => {
			let element: ReactElement | null = null,
				key = `feedback-${Math.random()}`;
			switch (subscription.type) {
				case 'card':
					element = (
						<div key={key}>
							<FeedbackCard
								feedbackable={feedbackable}
								onDismiss={() => subscription.onDismissCallback(key)}
								onSubmit={(rating, review) => {
									if (onSubmitCallback) onSubmitCallback(rating, review);
									subscription.onSubmitCallback(key, rating, review);
								}}
							/>
						</div>
					);
					break;
				case 'form':
					element = (
						<div key={key}>
							<FeedbackForm
								feedbackable={feedbackable}
								onDismiss={() => subscription.onDismissCallback(key)}
								onSubmit={(rating, review) => {
									if (onSubmitCallback) onSubmitCallback(rating, review);
									subscription.onSubmitCallback(key, rating, review);
								}}
							/>
						</div>
					);
					break;
			}
			subscription.onPromptCallback(element);
		});
	};

	/**
	 * Gets enabled state of controller
	 * @returns True if enabled
	 */
	public isEnabled: () => boolean = () => !this.disabled;

	/**
	 * Toggles the disabled state of controller
	 * @returns True if success
	 */
	public toggle: () => boolean = () => (this.disabled = !this.disabled);

	/**
	 * Enables the state of controller
	 * @returns True if success
	 */
	public enable: () => boolean = () => (this.disabled = false);

	/**
	 * Disables the state of controller
	 * @returns True if success
	 */
	public disable: () => boolean = () => (this.disabled = true);

	/**
	 * Toggles the state of the feedbackable matching the search criteria
	 * @param name name of feedbackable to toggle
	 * @returns True if success
	 */
	public toggleFeedbackable: (name: string) => boolean = (name) => {
		const { item } = this.getFeedbackable(name);
		if (!item) return false;
		item.disabled = !item.disabled;
		return true;
	};

	/**
	 * Enables the state of the feedbackable matching the search criteria
	 * @param name name of feedbackable to toggle
	 * @returns True if success
	 */
	public enableFeedbackable: (name: string) => boolean = (name) => {
		const { item } = this.getFeedbackable(name);
		if (!item) return false;
		item.disabled = false;
		return true;
	};

	/**
	 * Disables the state of the feedbackable matching the search criteria
	 * @param name name of feedbackable to toggle
	 * @returns True if success
	 */
	public disableFeedbackable: (name: string) => boolean = (name) => {
		const { item } = this.getFeedbackable(name);
		if (!item) return false;
		item.disabled = true;
		return true;
	};

	/**
	 * Gets enabled state of feedbackable
	 * @param name name of feedbackable
	 * @returns True if enabled
	 */
	public isFeedbackableEnabled: (name: string) => boolean = (name) => {
		const { item } = this.getFeedbackable(name);
		return !item?.disabled;
	};

	/**
	 * Subscribes the callback to the prompt event of the feedbackable
	 * @param name Name of feedbackable to subscribe to
	 * @param callback Callback for subscription to be called on prompt events
	 */
	public subscribe = ({ name, subscription }: FeedbackableSubscribeEvent) => {
		this.subscriptions[name] = this.subscriptions[name]
			? [...this.subscriptions[name], subscription]
			: [subscription];
	};

	/**
	 * Calls all subscirptions callbacks tied to the feedbackable
	 * @param name Name of the feedbackable (feature, behavior...)
	 */
	public prompt = (
		name: string,
		onSubmitCallback?: (rating: number, review: string | null) => void
	) => {
		if (this.disabled) return;
		const { item } = this.getFeedbackable(name);
		if (!item || item.disabled) return;

		setTimeout(
			() => this.callbackSubscriptions(name, item, onSubmitCallback),
			item.initialDelay ?? 0
		);
	};

	constructor(public feedbackables: Feedbackable[]) {}
}

type Props = {
	feedbackables: Feedbackable[];
	settingsController: SettingsController;
};

const useFeedbackController = ({ feedbackables, settingsController }: Props) => {
	// a ref, because we dont ever change the created instance
	const controller = useRef(new _FeedbackController(feedbackables));

	// Use settings
	const { settings } = settingsController;
	useMemo(() => {
		// General feedback enabled
		let isEnabled =
			settings[SettingPageSectionHeaders.APP_SETTINGS].children[SettingPageHeaders.FEEDBACK]
				.children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS].children[
				SettingHeaders.FEEDBACK_GENERAL
			].value;
		if (isEnabled) controller.current.enable();
		else controller.current.disable();

		// Session end feedback enabled
		let feedbackableName = 'sessionEnd';
		isEnabled =
			settings[SettingPageSectionHeaders.APP_SETTINGS].children[SettingPageHeaders.FEEDBACK]
				.children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS].children[
				SettingHeaders.FEEDBACK_SESSION_END
			].value;
		if (isEnabled) controller.current.enableFeedbackable(feedbackableName);
		else controller.current.disableFeedbackable(feedbackableName);

		// Switch feedback enabled
		feedbackableName = 'GoBeSwitch';
		isEnabled =
			settings[SettingPageSectionHeaders.APP_SETTINGS].children[SettingPageHeaders.FEEDBACK]
				.children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS].children[
				SettingHeaders.FEEDBACK_SWITCH
			].value;
		if (isEnabled) controller.current.enableFeedbackable(feedbackableName);
		else controller.current.disableFeedbackable(feedbackableName);

		return settings;
	}, [settings]);

	return controller.current as FeedbackController;
};

export default useFeedbackController;
