import './index.scss';

import noVideoIcon from 'images/no-video.png';
// import chromePermissionSettingsImage from "images/chrome-camera-permission-settings.png";
import chromePermissionSettingsImage from 'images/permissionsGuide.jpg';

import {
	LocalMediaAccessError,
	MediaDeviceNotFoundError,
	MediaPermissionError,
} from 'pages/session/videos/localVideo';
import { useEffect, useRef, useState } from 'react';

const SCREEN_TIMEOUT_MS = 30 * 1000;

interface MediaAccessErrorOverlayProps {
	isVisible: boolean;
	onEndSession: () => void;
	robotName: string;
	error: LocalMediaAccessError | null;
}

const getErrorDescription = ({
	error,
	robotName,
}: {
	error: LocalMediaAccessError | null;
	robotName: string;
}) => {
	if (error instanceof MediaDeviceNotFoundError) {
		return `You do not appear to have a ${error.deviceKind} connected.\nPlease connect a camera/microphone and reload.`;
	} else if (error instanceof MediaPermissionError) {
		return `You must grant access to both camera and microphone.\nPlease grant access to camera/microphone and reload.`;
	} else {
		return `Unable to access your camera or microphone.\nClose any other application using the camera/microphone and reload.`;
	}
};

// TODO: This overlay needs a massive UI/UX redesign.

function MediaAccessOverlayInner({ onEndSession, robotName, error }: MediaAccessErrorOverlayProps) {
	const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
	const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval>>();

	const [timeRemainingMs, setTimeRemainingMs] = useState(SCREEN_TIMEOUT_MS);
	const timeRemainingMsRef = useRef(timeRemainingMs);

	useEffect(() => {
		const timeoutId = setTimeout(onEndSession, timeRemainingMsRef.current);
		setTimeoutId(timeoutId);

		const intervalId = setInterval(() => {
			if (timeRemainingMsRef.current <= 0) {
				clearInterval(intervalId);
			} else {
				timeRemainingMsRef.current -= 1000;
				setTimeRemainingMs(timeRemainingMsRef.current);
			}
		}, 1000);
		setIntervalId(intervalId);

		return () => {
			clearTimeout(timeoutId);
			clearTimeout(intervalId);
		};
	}, [onEndSession]);

	const handleReloadButtonClicked = () => {
		if (intervalId !== undefined) clearInterval(intervalId);
		if (timeoutId !== undefined) clearTimeout(timeoutId);

		window.location.reload();
	};

	return (
		<div className="media-devices-error-overlay session-overlay">
			<div className="split-pane">
				<div className="left-pane">
					<div className="left-pane-inner">
						<img className="error-icon" alt="no-video-icon" src={noVideoIcon} />
						<div className="error-message">{getErrorDescription({ error, robotName })}</div>
					</div>
				</div>
				<div className="right-pane">
					<img
						className="media-devices-permissions-settings"
						alt="media-devices-permission-settings"
						src={chromePermissionSettingsImage}
					/>
				</div>
			</div>

			<div className="action-buttons-container">
				<div className="action-button end-session-button" onClick={() => onEndSession()}>
					End Session
				</div>

				<div className="action-button reload-page-button" onClick={handleReloadButtonClicked}>
					Reload Page
				</div>
			</div>

			<div className="time-remaining">{`This session will automatically end after ${(
				timeRemainingMsRef.current / 1000
			).toFixed(0)} seconds`}</div>
		</div>
	);
}

export default function MediaAccessErrorOverlay(props: MediaAccessErrorOverlayProps) {
	return props.isVisible ? <MediaAccessOverlayInner {...props} /> : null;
}
