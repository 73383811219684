import { useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';
import { LocalSessionInfo } from 'types';
import './index.scss';

const reduxConnector = connect(
	(state: AppRootState) => ({
		sessionStartDate: state.sessionState.sessionStartDate,
	}),
	{}
);

type PropsFromParent = {
	reservation: LocalSessionInfo['reservation'];
	setReservationSessionEnded?: any;
	onHangupTimeout: (timeout: number) => void;
};

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

/** The window time to start showing end session warning  */
const SHOW_END_SESSION_WARNING_WINDOW_MS = 5 * 60 * 1000;

const SessionEndWarning: React.FC<ComponentProps> = ({
	reservation,
	setReservationSessionEnded,
	sessionStartDate,
	onHangupTimeout,
}: ComponentProps) => {
	const [endSessionAfterInMs] = useState<number>(
		(() => {
			const now = +new Date();
			return (
				+new Date(
					reservation?.isMyPermanentDevice
						? reservation?.othersNextReservation?.startDate!
						: reservation?.currentReservation?.endDate!
				) - now
			);
		})()
	);
	const [showWarningTimeoutId, setShowWarningTimeoutId] = useState<ReturnType<typeof setTimeout>>();
	const [showWarningIntervalId, setShowWarningIntervalId] =
		useState<ReturnType<typeof setInterval>>();
	const [endSessionETA, setEndSessionETA] = useState<number>();
	const [displayWarning, setDisplayWarning] = useState<boolean>(false);
	const [timerDuration] = useState<number>(
		Math.min(
			SHOW_END_SESSION_WARNING_WINDOW_MS,
			sessionStartDate.getTime() + endSessionAfterInMs - +new Date()
		)
	);
	const documentTitle = document.title;
	useEffect(() => {
		if (!(reservation?.isMyPermanentDevice && !reservation?.othersNextReservation)) {
			!showWarningTimeoutId &&
				setShowWarningTimeoutId(
					setTimeout(() => {
						if (!showWarningIntervalId) {
							let eta = timerDuration;
							setEndSessionETA(eta);
							setShowWarningIntervalId(
								setInterval(() => {
									eta -= 1000;
									if (eta >= 0) {
										setEndSessionETA(eta);
										let tempDocumentTitle = `Session ends in ${
											eta! / 1000 >= 60
												? `${Math.ceil(eta! / 1000 / 60)} m`
												: `${Math.floor(eta! / 1000)} s`
										}`;
										document.title = document.hasFocus()
											? documentTitle
											: eta! / 1000 >= 60
											? document.title === documentTitle
												? tempDocumentTitle
												: documentTitle
											: tempDocumentTitle;
									} else {
										document.title = documentTitle;
										setReservationSessionEnded(true);
									}
								}, 1 * 1000)
							);
							setDisplayWarning(true);
						}
						onHangupTimeout(timerDuration);
					}, endSessionAfterInMs - SHOW_END_SESSION_WARNING_WINDOW_MS)
				);
		}

		return () => {
			document.title = documentTitle;
			clearInterval(showWarningIntervalId!);
			setShowWarningIntervalId(undefined);
			clearTimeout(showWarningTimeoutId!);
			setShowWarningTimeoutId(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return displayWarning ? (
		<div className="msgContainer">
			<div className="msgContainerTimer">
				<CountdownCircleTimer
					isPlaying
					size={24}
					strokeWidth={2}
					trailColor="##58B255"
					duration={timerDuration / 1000}
					colors="#FFFFFF"
					rotation="counterclockwise"
				/>
			</div>
			The session will automatically end due to the reservation in{' '}
			{endSessionETA! / 1000 >= 60
				? `${Math.ceil(endSessionETA! / 1000 / 60)} minutes`
				: `${Math.floor(endSessionETA! / 1000)} seconds`}
		</div>
	) : null;
};

export default reduxConnector(SessionEndWarning);
