import HelpCenterAnchor from 'components/helpCenterAnchor';
import Snackbar from 'components/snackbar';
import { Stats } from 'GoBeWebRTC/types';
import icon from 'images/red-warning.svg';
import whiteCloseChevronIcon from 'images/white-close-chevron.svg';
import { useMemo } from 'react';
import './index.scss';

type Props = {
	stats: Stats;
};

export default function PeerConnectionStatusIndicator({ stats }: Props) {
	const isVisible = useMemo<boolean>(
		() => stats?.succeededCandidatePair?.local?.relayProtocol === 'tls',
		[stats?.succeededCandidatePair?.local?.relayProtocol]
	);

	return isVisible ? (
		<Snackbar
			icon={icon}
			iconWrapper={whiteCloseChevronIcon}
			title="Network issue detected"
			actionRequired={
				<div className="peerConnectionStatusSnackbar">
					<span>The recommended UDP traffic is being blocked. </span>
					<span>
						For more info check out the <HelpCenterAnchor />
					</span>
				</div>
			}
		/>
	) : null;
}
