import { setParameter } from 'actions/setParam';
import LocalVideoHeader from 'components/localVideoHeader';
import React, {
	CSSProperties,
	useCallback,
	useContext,
	useEffect,
	useEffect as useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';
import { LocalSessionInfo, PrimaryCameraState } from 'types';
import './index.scss';

import whiteGoBeLogo from 'images/white-gobe-logo.svg';

import Draggable from 'components/draggable';
import { AppContext } from 'context/appContext';
import {
	LOCAL_MEDIA_CONSTRAINTS,
	RobotStatus,
	ConnectionsQuality,
	SessionState,
	Stats,
} from 'GoBeWebRTC/types';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import adapter from 'webrtc-adapter';
import { LocalMedia } from 'webRTC/types';
import ObjectSegmentation from '../objectSegmentation';
import { SelectLocalCameras } from './selectLocalCameras';

type PropsFromParent = {
	robotStatus: RobotStatus;
	connectionsQuality: ConnectionsQuality;
	startWideCameraStats: Function;
	stopWideCameraStats: Function;
	wideCameraStats: any;
	isGreyedOut: boolean;
	isPaused: boolean;
	shouldShowLoadingIndicator: boolean;
	media: LocalMedia;
	primaryMedia: MediaStream | null;
	primaryRotation: number;
	navMedia: MediaStream | null;
	navRotation: number;
	robotLocalCamerasSupported: boolean;
	setLocalDisplays: ({}) => void;
	primaryCameraState: PrimaryCameraState;
	sessionState: SessionState;
	stats: Stats;
	sessionId: string;
};

const reduxConnector = connect(
	(state: AppRootState) => ({
		controlDataChannel: state.sessionState.controlDataChannel,
		localVoiceVolume: state.sessionState.localVoiceVolume,
		dragMode: state.sessionState.dragMode,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const LocalVideo: React.FC<ComponentProps> = ({
	robotStatus,
	connectionsQuality,
	controlDataChannel,
	isGreyedOut,
	isPaused,
	shouldShowLoadingIndicator,
	media,
	primaryMedia,
	primaryRotation,
	navMedia,
	navRotation,
	robotLocalCamerasSupported,
	setLocalDisplays,
	primaryCameraState,
	sessionState,
	stats,
	dragMode,
	sessionId,
}) => {
	const {
		navController: { enable: enableNavController, disable: disableNavController },
		settingsController,
	} = useContext(AppContext);
	const mediaStream = media?.media?.stream ?? null;

	const [isLocalVideoViewExpanded, setIsLocalVideoViewExpanded] = useState(true);
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const [statusMessage, setStatusMessage] = useState<string>('');
	const videoStyle: CSSProperties = {
		width: 164,
		height: 200,
	};

	const [voiceLevel, setVoiceLevel] = useState(11); // FIXME: What is this magic number? Originally added by Meisam

	const objectSegmentationCanvasRef = useRef<HTMLCanvasElement | null>(null);

	useLayoutEffect(() => {
		if (!mediaStream) return;

		const audioContext = new AudioContext();

		const analyser = audioContext.createAnalyser();
		const microphone = audioContext.createMediaStreamSource(mediaStream);
		const scriptProcessorNode = audioContext.createScriptProcessor(2048, 1, 1);

		analyser.smoothingTimeConstant = 0.8;
		analyser.fftSize = 1024;

		microphone.connect(analyser);
		analyser.connect(scriptProcessorNode);
		scriptProcessorNode.connect(audioContext.destination);

		const onAudioProcess = () => {
			let array = new Uint8Array(analyser.frequencyBinCount);
			analyser.getByteFrequencyData(array);
			let values = 0;

			let length = array.length;
			for (let i = 0; i < length; i++) {
				values += array[i];
			}

			let average = values / length;

			if (average > 100) {
				average = 100;
			}
			setVoiceLevel((average / 100) * 12);
		};
		scriptProcessorNode.addEventListener('audioprocess', onAudioProcess);

		return () => {
			microphone.disconnect();
			analyser.disconnect();
			scriptProcessorNode.disconnect();
			scriptProcessorNode.removeEventListener('audioprocess', onAudioProcess);

			audioContext.close().catch((error) => console.error('Error closing AudioContext', error));
		};
	}, [mediaStream]);

	useLayoutEffect(() => {
		if (!mediaStream || !videoRef.current) return;
		videoRef.current.srcObject = mediaStream;
	}, [mediaStream]);

	const sendStatusMessage = useCallback<(message?: string) => void>(
		(message) => {
			if (controlDataChannel && controlDataChannel.readyState === 'open') {
				controlDataChannel.send(`MSG ${message ?? statusMessage}`);
			}
		},
		[statusMessage, controlDataChannel]
	);

	const sendStatusMessageJson = useCallback<(message?: string) => void>(
		(message) => {
			let payload = {
				type: 'status_message',
				message: message ?? statusMessage,
			};

			if (controlDataChannel && controlDataChannel.readyState === 'open') {
				controlDataChannel.send(JSON.stringify(payload));
			}
		},
		[statusMessage, controlDataChannel]
	);

	useMemo(() => {
		if (sessionState === 'InProgress') {
			sendStatusMessage();
			sendStatusMessageJson();
		}
		return sessionState;
	}, [sessionState]);

	const onStatusMessageInputChange = (value: string) => {
		setStatusMessage(value);
		sendStatusMessage(value);
		sendStatusMessageJson(value);
	};

	const onCanPlay = () => {
		if (!isPaused) {
			videoRef.current?.play().catch((error) => console.warn('Unable to play LocalVideo', error));
		} else videoRef.current?.pause();
	};

	useLayoutEffect(() => {
		if (isPaused === true) videoRef.current?.pause();
		else {
			videoRef.current?.play().catch((error) => console.warn('Unable to play LocalVideo', error));
		}
	}, [isPaused]);

	// Use object detection
	const selfieSegmentationEnabled = useMemo(
		() =>
			settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
				SettingPageHeaders.IMAGE_RECOGNITION
			].children[SettingTabHeaders.OBJECT_SEGMENTATION].children[SettingSectionHeaders.SEGMENTATION]
				.children[SettingHeaders.ENABLE_SELFIE_SEGMENTATION].value,
		[settingsController.settings]
	);

	useEffect(() => {
		if (!media?.transceiver) return;
		if (mediaStream!.getAudioTracks().length > 0) return;
		if (selfieSegmentationEnabled) {
			const segmentationStream = objectSegmentationCanvasRef.current?.captureStream();
			media.transceiver.sender.replaceTrack(segmentationStream?.getVideoTracks()[0]!);
		} else {
			media.transceiver.sender.replaceTrack(mediaStream?.getVideoTracks()[0]!);
		}
	}, [selfieSegmentationEnabled, media]);

	const RenderVideoLoading = () => {
		const [isHovered, setIsHovered] = useState(false);

		return (
			<div
				className={isLocalVideoViewExpanded ? 'localVideoExpanded' : 'displayNone'}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<div className={!shouldShowLoadingIndicator ? 'displayNone' : 'showLocalLoading '}>
					<div className="localLoading" />
				</div>
				<video
					ref={videoRef}
					onCanPlay={onCanPlay}
					playsInline
					loop
					muted
					className={
						!shouldShowLoadingIndicator
							? isGreyedOut
								? 'localVideo greyVideo'
								: 'localVideo'
							: 'displayNone'
					}
					id="localVideo"
					style={{
						...videoStyle,
						...(selfieSegmentationEnabled ? { visibility: 'hidden' } : {}),
						...{
							transform: settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS]
								?.children[SettingPageHeaders.AUDIOVIDEO]?.children[SettingTabHeaders.GENERAL]
								?.children[SettingSectionHeaders.AUDIOVIDEO]?.children[
								SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY
							]?.value
								? ' scaleX(1)'
								: ' scaleX(-1)',
						},
					}}
				/>
				{selfieSegmentationEnabled && videoRef?.current && videoStyle.height ? (
					<div className="localVideoBackground" style={videoStyle}>
						<ObjectSegmentation
							canvasRef={objectSegmentationCanvasRef}
							video={videoRef?.current}
							dimensions={{
								width: LOCAL_MEDIA_CONSTRAINTS.video?.width as number,
								height: LOCAL_MEDIA_CONSTRAINTS.video?.height as number,
							}}
							callbacks={{}}
							options={{}}
						/>
					</div>
				) : null}
				<img
					className={!shouldShowLoadingIndicator ? 'whiteLogoWrapper' : 'displayNone'}
					src={whiteGoBeLogo}
					alt=""
				/>
				<div className={!shouldShowLoadingIndicator ? 'audioRecognizeContainer' : 'displayNone'}>
					<div className="audioStrength" style={{ height: `${4 + voiceLevel / 2}px` }} />
					<div className="audioStrength" style={{ height: `${4 + voiceLevel}px` }} />
					<div className="audioStrength" style={{ height: `${4 + voiceLevel / 2}px` }} />
				</div>
				{robotLocalCamerasSupported && (
					<SelectLocalCameras
						isHovered={isHovered}
						primaryMedia={primaryMedia}
						primaryRotation={primaryRotation}
						navMedia={navMedia}
						navRotation={navRotation}
						setLocalDisplays={setLocalDisplays}
						primaryCameraState={primaryCameraState}
						// Greying out does not happen on the robot, represent this in the preview
						shouldShowLoadingIndicator={
							shouldShowLoadingIndicator || isGreyedOut || sessionState === 'SoftRetrying'
						}
					/>
				)}
			</div>
		);
	};

	const localVideoHeader = useMemo(
		() => (
			<LocalVideoHeader
				robotStatus={robotStatus}
				connectionsQuality={connectionsQuality}
				onToggleLocalVideoExpansion={() => setIsLocalVideoViewExpanded((state) => !state)}
				isLocalVideoExpanded={isLocalVideoViewExpanded}
				sessionState={sessionState}
				stats={stats}
				sessionId={sessionId}
			/>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isLocalVideoViewExpanded, robotStatus, sessionState, { ...stats }]
	);

	return (
		<Draggable
			mode={dragMode as any}
			content={
				<>
					<div
						className={
							isLocalVideoViewExpanded ? 'localContainer' : 'localContainer miniLocalContainer'
						}
					>
						<div>{localVideoHeader}</div>
						{RenderVideoLoading()}
					</div>
					<div className={isLocalVideoViewExpanded ? 'localInputContainer' : 'displayNone'}>
						<input
							placeholder="Enter a status here"
							value={statusMessage}
							onChange={(event) => onStatusMessageInputChange(event.target.value)}
							onFocus={() => disableNavController()}
							onBlur={() => enableNavController()}
							id="localInputContainer"
							autoComplete="off"
						/>
					</div>
				</>
			}
			style={{
				bottom: 16,
				right: 15,
			}}
		/>
	);
};

export default reduxConnector(LocalVideo);

export class MediaDeviceNotFoundError extends Error {
	constructor(public readonly deviceKind: 'camera' | 'microphone' | 'speakers') {
		super(`No connected ${deviceKind} was found`);
	}
}

export class MediaPermissionError extends Error {
	constructor(public readonly deviceKind: 'camera' | 'microphone' | 'speakers') {
		super(`Permission denied. Cannot access ${deviceKind}.`);
	}
}

export type LocalMediaAccessError = MediaDeviceNotFoundError | MediaPermissionError | Error;

/// Code below is unused
//  Seems like a waste to delete it,
//  but feel free to do so if this message is still here in a year :)

// /** A version of `navigator.enumerateDevices`, that throws if we dont have permission to access media devices */
// const enumerateDevices = async (): Promise<MediaDeviceInfo[]> => {
// 	const { browserDetails } = adapter;
// 	let hasCameraPermission: boolean;
// 	let hasMicrophonePermission: boolean;

// 	const devices = await navigator.mediaDevices.enumerateDevices();

// 	const hasCamera = !!devices.find((d) => d.kind === 'videoinput');
// 	const hasMicrophone = !!devices.find((d) => d.kind === 'audioinput');

// 	if (browserDetails.browser === 'chrome' && (browserDetails.version ?? 0) >= 86) {
// 		hasCameraPermission =
// 			(await navigator.permissions.query({ name: 'camera' as never }))?.state === 'granted';
// 		hasMicrophonePermission =
// 			(await navigator.permissions.query({ name: 'microphone' as never }))?.state === 'granted';
// 	} else {
// 		hasCameraPermission = !!devices.find((d) => d.kind === 'videoinput' && d.deviceId !== '');
// 		hasMicrophonePermission = !!devices.find((d) => d.kind === 'audioinput' && d.deviceId !== '');
// 	}

// 	console.debug('Local media devices status', {
// 		hasCamera,
// 		hasMicrophone,
// 		hasCameraPermission,
// 		hasMicrophonePermission,
// 	});

// 	if (!hasCamera) {
// 		throw new MediaDeviceNotFoundError('camera');
// 	} else if (!hasMicrophone) {
// 		throw new MediaDeviceNotFoundError('microphone');
// 	} else if (!hasCameraPermission) {
// 		throw new MediaPermissionError('camera');
// 	} else if (!hasMicrophonePermission) {
// 		throw new MediaPermissionError('microphone');
// 	}

// 	return devices;
// };

// /** Custom getUserMedia implementation that allows matching by name of preferred devices */
// const getUserMedia = async (
// 	constraints: MediaStreamConstraints,
// 	preferredDevices: NonNullable<LocalSessionInfo['devices']> | null
// ): Promise<{ stream: MediaStream; audioOutputId: string | undefined }> => {
// 	const allMediaDevices = await enumerateDevices();

// 	const mediaConstraints = { ...constraints };

// 	let audioOutputId: string | undefined;

// 	const {
// 		camera: preferredCamera,
// 		microphone: preferredMicrophone,
// 		speaker: preferredSpeakers,
// 	} = preferredDevices ?? {};

// 	const prefersSpecificMediaDevices = !!(
// 		preferredCamera?.name ||
// 		preferredMicrophone?.name ||
// 		preferredSpeakers?.name
// 	);

// 	if (prefersSpecificMediaDevices) {
// 		console.debug('PreferredDevices:', preferredDevices);

// 		const preferredCameraId = allMediaDevices.find(
// 			(device) => device.kind === 'videoinput' && device.label === preferredCamera?.name
// 		)?.deviceId;
// 		if (preferredCameraId) {
// 			mediaConstraints.video = {
// 				...(typeof mediaConstraints.video === 'boolean' ? {} : mediaConstraints.video),
// 				deviceId: { exact: preferredCameraId },
// 			};
// 		}

// 		const preferredMicId = allMediaDevices.find(
// 			(device) => device.kind === 'audioinput' && device.label === preferredMicrophone?.name
// 		)?.deviceId;
// 		if (preferredMicId) {
// 			mediaConstraints.audio = {
// 				...(typeof mediaConstraints.audio === 'boolean' ? {} : mediaConstraints.audio),
// 				deviceId: { exact: preferredMicId },
// 			};
// 		}

// 		audioOutputId = allMediaDevices.find(
// 			(device) => device.kind === 'audiooutput' && device.label === preferredSpeakers?.name
// 		)?.deviceId;
// 	} else {
// 		console.debug('No PreferredDevices');
// 	}

// 	const stream = await navigator.mediaDevices
// 		.getUserMedia(mediaConstraints)
// 		.then((stream) => {
// 			console.debug('getUserMedia() -> from preferredMediaDevices');
// 			return stream;
// 		})
// 		.catch((error) => {
// 			console.error(`getUserMedia() -> from preferredMediaDevices`, error);
// 			return navigator.mediaDevices
// 				.getUserMedia({ audio: true, video: true })
// 				.then((stream) => {
// 					console.log('getUserMedia() -> from generic constraints');
// 					return stream;
// 				})
// 				.catch((error) => {
// 					console.error('getUserMedia() -> from generic constraints', error);
// 					throw error;
// 				});
// 		});

// 	return { stream, audioOutputId };
// };

// /** Hook that auto-gets user media when mounted. */
// export const useLocalMedia = (
// 	constraints: MediaStreamConstraints,
// 	preferredDevices: NonNullable<LocalSessionInfo['devices']> | null
// ) => {
// 	const args = useRef({ constraints, preferredDevices });

// 	const [media, setMedia] = useState<{
// 		stream: MediaStream;
// 		audioOutputId?: string;
// 	} | null>(null);
// 	const [error, setError] = useState<LocalMediaAccessError | null>(null);

// 	useEffect(() => {
// 		const { constraints, preferredDevices } = args.current;

// 		getUserMedia(constraints, preferredDevices)
// 			.then(setMedia)
// 			.catch((error) => {
// 				setMedia(null);
// 				setError(error);
// 			});
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, []);

// 	return { media, error };
// };
