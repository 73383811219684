import React from 'react';
import './index.scss';

import cloudOfflineIcon from 'images/cloud-offline.svg';
import { ReactComponent as CloseIcon } from 'images/close.svg';

type Props = {
	isVisible: boolean;
	closeSession: () => void;
	robotName: string;
};

export default function SessionNetworkFailureOverlay({
	isVisible,
	closeSession: backClick,
	robotName,
}: Props) {
	return isVisible ? (
		<div className="failedConnectionContainer">
			<img className="warningIconWrapper" alt="" src={cloudOfflineIcon} />
			<div className="warningText">
				Your session on {robotName} was lost due to network problems.
			</div>
			<div className="failedAgainButtonsContainer">
				<div className="circleTextContainer">
					<div className="redCircle blackCircle" onClick={() => backClick()}>
						<div className="iconWrapper rotateNinety">
							<CloseIcon />
						</div>
					</div>
					<div>End</div>
				</div>
			</div>
		</div>
	) : null;
}
