import { useEffect } from 'react';

import { ReactZoomPanPinchContextState } from '../models';
import { getState } from '../utils';
import { useTransformContext } from './use-transform-context';

export const useTransformEffect = (
	callback: (ref: ReactZoomPanPinchContextState) => void | (() => void)
): void => {
	const libraryContext: any = useTransformContext();

	useEffect(() => {
		let unmountCallback: void | (() => void);
		const unmount = libraryContext.onChange((ref: any) => {
			unmountCallback = callback(getState(ref.instance));
		});
		return () => {
			unmount();
			unmountCallback?.();
		};
	}, [callback, libraryContext]);
};
