import { AppContext } from 'context/appContext';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import './index.scss';

type Props = {
	onRemoveFeedbackElement?: () => any;
};

const FeedbackNotificationBar: React.FC<Props> = ({}) => {
	const {
		feedbackController,
		navController: { enable: enableNavController, disable: disableNavController },
	} = useContext(AppContext);
	const [feedbackElements, setFeedbackElements] = useState<ReactElement[]>([]);
	const removeFeedbackElement = (key: string) => {
		setFeedbackElements((prev) => prev.filter((feedbackElement) => feedbackElement.key !== key));
	};

	// Subscribe to feedbackables
	useEffect(() => {
		feedbackController.subscribe({
			name: 'GoBeSwitch',
			subscription: {
				type: 'card',
				onDismissCallback: (key: string) => removeFeedbackElement(key),
				onSubmitCallback: (key: string, rating: number, review: string | null) => {
					removeFeedbackElement(key);
					console.log(rating, review);
				},
				onPromptCallback: (element: ReactElement) =>
					setFeedbackElements((prev) => [...prev, element]),
			},
		});
	}, []);

	useEffect(() => {
		if (!feedbackElements.length) enableNavController();
	}, [feedbackElements.length]);

	return feedbackElements?.length ? (
		<div
			className="feedbackNotificationBar"
			onFocus={disableNavController}
			onClickCapture={disableNavController}
			onMouseEnter={disableNavController}
			onMouseOver={disableNavController}
			onMouseMoveCapture={disableNavController}
			onBlur={enableNavController}
			onBlurCapture={enableNavController}
			onMouseLeave={enableNavController}
		>
			{feedbackElements.slice(0, 1).map((feedbackElement) => feedbackElement)}
		</div>
	) : null;
};

export default FeedbackNotificationBar;
