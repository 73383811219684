import React, { useEffect, useRef, useState } from 'react';

type Props = {
	initialMinute?: number;
	initialSeconds?: number;
	classname?: string;
};

const Timer: React.FC<Props> = (props) => {
	const { initialSeconds = 0, classname } = props;
	const [secondsPassed, setSecondsPassed] = useState(initialSeconds);
	const startDateRef = useRef<number>(+new Date());
	const intervalIdRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

	useEffect(() => {
		if (intervalIdRef.current === undefined)
			intervalIdRef.current = setInterval(() => {
				setSecondsPassed(Math.floor((+new Date() - startDateRef.current) / 1000));
			}, 1000);
		return () => {
			clearInterval(intervalIdRef.current!);
		};
	}, []);

	const hours = Math.floor(secondsPassed / 3600);
	const minutes = Math.floor((secondsPassed % 3600) / 60);
	const seconds = secondsPassed % 60;

	return (
		<span className={classname}>
			{hours}:{minutes < 10 ? '0' : ''}
			{minutes}:{seconds < 10 ? '0' : ''}
			{seconds}
		</span>
	);
};

export default Timer;
