import FeedbackForm, { FeedbackFormProps } from 'components/feedbackForm';
import React, { useEffect, useMemo, useState } from 'react';
import './index.scss';
const notificationSound = require('../../audio/notification-sound.mp3');

function FeedbackCard({ feedbackable, onDismiss, ...rest }: FeedbackFormProps) {
	// Timeout
	const { timeout, muteSound } = feedbackable;
	// Display
	const [show, setShow] = useState<boolean | null>(true);
	// Display timeout
	const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
	// Fade effect
	const FADING_EFFECT_DURATION_MS = 300;

	// Dismiss
	const dismiss = () => {
		setShow(false);

		if (onDismiss) {
			setTimeout(() => onDismiss(), FADING_EFFECT_DURATION_MS);
		}
	};

	// Play notification sound and start hiding timeout
	useEffect(() => {
		if (!muteSound) {
			const audio = new Audio(notificationSound);
			audio.volume = 0.5;
			audio.play();
		}

		if (timeout) {
			setTimeoutId(setTimeout(dismiss, timeout));
		}
		return () => {
			onFocus();
		};
	}, []);

	const timerStyle = {
		animation: `collapseFeedbackTimeoutWrapper ${timeout! / 1000}s ease-in`,
		WebkitAnimation: `collapseFeedbackTimeoutWrapper ${timeout! / 1000}s ease-in`,
		animationFillMode: 'forwards',
		WebkitAnimationFillMode: 'forwards',
	};
	const onFocus = () => {
		if (undefined === timeout) return;
		clearTimeout(timeoutId!);
		setTimeoutId(undefined);
	};

	const fadingStyle = {
		animation: `collapseFeedbackCardWrapper ${FADING_EFFECT_DURATION_MS! / 1000}s ease-out`,
		WebkitAnimation: `collapseFeedbackCardWrapper ${FADING_EFFECT_DURATION_MS! / 1000}s ease-out`,
		animationFillMode: 'forwards',
		WebkitAnimationFillMode: 'forwards',
	};
	// Handle fade away
	useMemo(() => {
		if (!show) setTimeout(() => setShow(null), FADING_EFFECT_DURATION_MS);

		return show;
	}, [show]);

	return show !== null ? (
		<div
			className="feedbackCardWrapper"
			style={show === false ? fadingStyle : {}}
			onMouseEnter={onFocus}
			onClickCapture={onFocus}
			onMouseOver={onFocus}
			onMouseMoveCapture={onFocus}
			onFocus={onFocus}
		>
			{timeout && timeoutId ? (
				<div className="feedbackCardTimeoutWrapper" style={timerStyle} />
			) : null}
			<FeedbackForm feedbackable={feedbackable} onDismiss={dismiss} {...rest} />
		</div>
	) : null;
}

export default React.memo(FeedbackCard);
