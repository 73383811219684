import React, { Component, ErrorInfo } from 'react';
import greenGobeLogo from 'images/green-gobe-logo.svg';
import './ErrorBoundary.css';

/* FIXME: This component has only been implemented to fix an error of too many re-renders when ending
   temporary access sessions, resulting in a black screen due to app crashing and defaulting to public/index.html. 
   It should be removed when a permanent solution to the re-rendering issue has been implemented */
interface ErrorBoundaryProps {
	children: React.ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(_: Error): ErrorBoundaryState {
		// Update state to indicate that an error has occurred
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// You can log the error to an error reporting service
		console.error('Error caught by ErrorBoundary:', error, errorInfo);
		// Close the current tab when an error occurs
		// window.close();
	}

	render() {
		if (this.state.hasError) {
			// Render fallback UI when an error occurs
			return (
				<div className="endSessionWrapper">
					<div className="container">
						<img src={greenGobeLogo} alt="" />
						<div className="session">Session ended</div>
						<div className="endMsg">The session has ended. You may close the session tab.</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
