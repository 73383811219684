let NavLineConfig = {
	angularVelocity: 0,
	linearVelocity: 0,
	linearThrottle: 0,
	timeDuration: 8,
	MAX_LIN_FORWARD_VEL: 0.4, // Should be 0.55, but robot performs a bit slower in reality (we don't take acceleration into account here either)
	MAX_LIN_BACKWARD_VEL: 0.25, // Should be 0.3. Absolute value, this is ofc. negative
	MAX_ANG_VEL: 1,
	LINEAR_DEADZONE: 0.25,
	invertBackwardSteering: false, // TODO: Not sure this setting belongs here, reevaluate when reworking driving input handling
};

//@ts-ignore
window.navLineSimSteps = 30;
//@ts-ignore
window.navLineWidth = 9;

// Define parameters
let shadowOffsetStep = 1 / 500;
//@ts-ignore
let numShadows = window.navLineWidth as number;
let startingOpacity = 0.75;
let shadowOpacityStep = startingOpacity / numShadows;

let timeDuration = NavLineConfig.timeDuration; // Time duration for simulation
//@ts-ignore
let step = NavLineConfig.timeDuration / window.navLineSimSteps; // Incremental steps for simulation

// Loading the A-frame scene is a bit slow, causing an issue where react will
// spawn the scene before A-frame is ready.
// This is then further complicated by the scene not being initially rendered
// if the use tabs out during the initial loading.
// Periodically forcing updates handles all edge cases on all browsers.
const intervalId = setInterval(() => window.dispatchEvent(new Event('resize')), 3000);

// Control variables for the rotation animation
let lastUpdateTime = Date.now();
let animationProgress = 0;

export function emitConfigUpdate() {
	const scenes = document.querySelectorAll('a-scene');
	scenes.forEach(function (scene) {
		scene.querySelectorAll('.navigation-line').forEach(function (lineCurveEl) {
			//@ts-ignore
			lineCurveEl.emit('config-update');
		});
	});
}

//@ts-ignore
import('aframe')
	.catch((e) => {
		console.warn(e);
		window.dispatchEvent(new Event('webglcontextlost'));
	})
	.then(() => {
		AFRAME.registerComponent('navigation-line', {
			schema: {
				// Initial Start Point
				startPoint: {
					type: 'vec2',
					default: { x: 0, y: 0 },
					parse: AFRAME.utils.coordinates.parse,
				},
				shadow: { type: 'boolean', default: true },
			},

			didInit: false,
			firstRenders: 0,

			vertexShader: null as any,
			fragmentShader: null as any,
			material: null as any,
			points3D: null as any,
			points3DLeft: null as any,
			points3DRight: null as any,
			vertexIndices: null as any,
			geometry: null as any,
			geometryLeft: null as any,
			geometryRight: null as any,
			line: null as any,
			shadowLine: null as any,

			// Initial creation and setting of the mesh.
			init: function () {
				// Render path
				this.renderPath();

				// Materials
				// Vertex shader
				this.vertexShader = `
				attribute float index;
				varying float vIndex;
				void main() {
				  vIndex = index;
				  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
				}
				`;

				// Fragment shader
				this.fragmentShader = `
				uniform vec3 color;
				uniform float length;
				uniform float startingOpacity;
				varying float vIndex;
				void main() {
					float gradient = startingOpacity * (vIndex / length); 
					gl_FragColor = vec4(color, gradient);
				}
				`;

				// Create the shader material
				let navColor = '#56AE4D';
				let shadowColor = '#56AE4D';

				this.material = new AFRAME.THREE.ShaderMaterial({
					uniforms: {
						color: { value: new AFRAME.THREE.Color(navColor).convertLinearToSRGB() },
						//@ts-ignore
						length: { value: window.navLineSimSteps },
						startingOpacity: { value: 1.0 },
					},
					vertexShader: this.vertexShader,
					fragmentShader: this.fragmentShader,
					side: AFRAME.THREE.DoubleSide,
					linewidth: 1, // Very few platforms support linewidths other than 1
					transparent: true,
				});

				// Persistent, pre instantiated variables to store positions
				this.points3D = [];
				this.points3DLeft = [];
				this.points3DRight = [];
				let ti = 0;
				for (let t = 0; t < timeDuration; t += step) {
					for (let i = 1; i <= numShadows; i++) {
						if (!this.points3DLeft[i]) {
							this.points3DLeft[i] = [];
						}
						if (!this.points3DRight[i]) {
							this.points3DRight[i] = [];
						}
						this.points3DLeft[i][ti] = new AFRAME.THREE.Vector3(0, 0, 0);
						this.points3DRight[i][ti] = new AFRAME.THREE.Vector3(0, 0, 0);
					}
					this.points3D[ti] = new AFRAME.THREE.Vector3(0, 0, 0);
					ti++;
				}

				this.vertexIndices = new Float32Array(this.points3D.length);
				for (let i = this.points3D.length - 1, j = 0; i >= 0; i--, j++) {
					this.vertexIndices[j] = i;
				}

				this.geometry = new AFRAME.THREE.BufferGeometry().setFromPoints(this.points3D);
				this.geometry.setAttribute(
					'index',
					new AFRAME.THREE.BufferAttribute(this.vertexIndices, 1)
				);

				this.geometryLeft = [];
				this.geometryRight = [];
				for (let i = 1; i <= numShadows; i++) {
					this.geometryLeft[i] = new AFRAME.THREE.BufferGeometry().setFromPoints(
						this.points3DLeft[i]
					);
					this.geometryLeft[i].setAttribute(
						'index',
						new AFRAME.THREE.BufferAttribute(this.vertexIndices, 1)
					);

					this.geometryRight[i] = new AFRAME.THREE.BufferGeometry().setFromPoints(
						this.points3DRight[i]
					);
					this.geometryRight[i].setAttribute(
						'index',
						new AFRAME.THREE.BufferAttribute(this.vertexIndices, 1)
					);
				}

				this.line = new AFRAME.THREE.Line(this.geometry as any, this.material);
				this.line.material = this.material;

				this.shadowLine = [];
				for (let i = 1; i <= numShadows; i++) {
					this.shadowLine[i] = new AFRAME.THREE.Line(this.geometry as any, this.material);
					this.shadowLine[i].material = this.material;
				}

				// Listen for configuration update events
				// @ts-ignore
				this.el.sceneEl.addEventListener('config-update', this.renderPath.bind(this));

				this.didInit = true;
			},
			renderPath: function () {
				if (!this.didInit) return;

				let data = this.data;
				let el = this.el;
				let standStillRotationEl = el.querySelector('#stand-still-rotation');

				let angularVelocity = NavLineConfig.angularVelocity; // Angular velocity (rate of change of angle)
				let linearVelocity =
					NavLineConfig.linearVelocity + data.startPoint.x * NavLineConfig.angularVelocity; // Linear velocity (speed)

				// Animation when purely rotating
				if (standStillRotationEl) {
					if (
						Math.abs(NavLineConfig.linearThrottle) >= NavLineConfig.LINEAR_DEADZONE ||
						NavLineConfig.angularVelocity == 0
					) {
						//@ts-ignore
						standStillRotationEl.setAttribute('material', 'visible', 'false');
					} else {
						//@ts-ignore
						standStillRotationEl.setAttribute('material', 'visible', 'true');
					}

					if (angularVelocity < 0) {
						standStillRotationEl.setAttribute('scale', '-1, 1, 1');
					} else {
						standStillRotationEl.setAttribute('scale', '1, 1, 1');
					}

					const CW = NavLineConfig.angularVelocity > 0;
					const currentTime = Date.now();
					const elapsedTime = currentTime - lastUpdateTime;
					lastUpdateTime = currentTime;
					animationProgress += elapsedTime * NavLineConfig.angularVelocity * 0.5; //*0.5 is magic, not sure why this makes it perfect
					animationProgress = animationProgress % 360;

					//@ts-ignore
					standStillRotationEl.setAttribute('animation', {
						property: 'rotation',
						from: `0 0 ${animationProgress}`,
						to: `0 0 ${animationProgress + (CW ? 360 : -360)}`,
						loop: true,
						dur: 1000 * (1 / Math.abs(NavLineConfig.angularVelocity)),
						easing: 'linear',
					});
				} else {
					if (Math.abs(NavLineConfig.linearThrottle) < NavLineConfig.LINEAR_DEADZONE) {
						linearVelocity = 0;
					}

					// God have mercy on the poor soul who want to clean up this "useless" piece of code.
					// Some part of our 3D stack has a bug (possibly due to React interaction)
					// resulting in any geometry not visible to the camera of the scene it belongs to,
					// for the first 2 frames, to permanently become invisible👻
					// By forcing the speed to be high enough for the lines to show on the wide cam scene
					// this issue can be avoided.
					if (this.firstRenders < 2) {
						this.firstRenders++;
						linearVelocity = 0.4;
					}

					// Find initial angle to ensure upward motion
					let theta = Math.PI / 2; // Pointing upward

					let updatedX = data.startPoint.x;
					let updatedY = data.startPoint.y;

					let ti = 0;
					for (let t = 0; t < timeDuration; t += step) {
						for (let i = 1; i <= numShadows; i++) {
							let normalLeftX = updatedX + Math.cos(theta + Math.PI / 2) * shadowOffsetStep * i;
							let normalLeftY = updatedY + Math.sin(theta + Math.PI / 2) * shadowOffsetStep * i;
							let normalRightX = updatedX + Math.cos(theta - Math.PI / 2) * shadowOffsetStep * i;
							let normalRightY = updatedY + Math.sin(theta - Math.PI / 2) * shadowOffsetStep * i;

							this.points3DLeft[i][ti].set(normalLeftX, normalLeftY, 0);
							this.points3DRight[i][ti].set(normalRightX, normalRightY, 0);
						}

						this.points3D[ti].set(updatedX, updatedY, 0);

						// Move the simulation along
						updatedX += linearVelocity * Math.cos(theta) * step;
						updatedY += linearVelocity * Math.sin(theta) * step;
						theta += angularVelocity * step;
						ti++;
					}

					this.geometry.setFromPoints(this.points3D);
					this.line.geometry = this.geometry as any;
					el.setObject3D('line', this.line);

					this.geometry.dispose();

					if (data.shadow) {
						for (let i = 1; i <= numShadows; i++) {
							this.geometryLeft[i].setFromPoints(this.points3DLeft[i]);
							this.geometryRight[i].setFromPoints(this.points3DRight[i]);

							if (data.startPoint.x > 0) {
								//@ts-ignore
								if (i <= window.navLineWidth) {
									this.shadowLine[i].geometry = this.geometryLeft[i] as any;
									el.setObject3D('shadowLine' + i, this.shadowLine[i]);
								}
							} else if (data.startPoint.x < 0) {
								//@ts-ignore
								if (i <= window.navLineWidth) {
									this.shadowLine[i].geometry = this.geometryRight[i] as any;
									el.setObject3D('shadowLine' + i, this.shadowLine[i]);
								}
							}
							this.geometryLeft[i].dispose();
							this.geometryRight[i].dispose();
						}
					}
				}
			},
		});
	});

// Disable AFRAME visual inspector (accessible using <ctrl>+<alt>+i keyboard shortcut)
document.addEventListener('keydown', (event) => {
	if (
		event.keyCode === 73 &&
		((event.ctrlKey && event.altKey) || event.getModifierState('AltGraph'))
	) {
		event.preventDefault();
		event.stopPropagation();
	}
});

export default NavLineConfig;
