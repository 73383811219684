// See https://www.w3.org/TR/webrtc-priority/ for info about what these values mean

// Experimental observations of DSCP values from Chrome (using Wireshark):
// networkPriority: 'high' = AF41 (video) or PHB EF (audio)
// networkPriority: 'medium' = AF42 (video) or PHB EF (audio)
// networkPriority: 'low' = CS0 (video) or PHB EF (audio)
// networkPriority: 'very-low' = CS1 (video and audio)

/**
 * Default send encoding for transceiver init
 */
export const DEFAULT_VIDEO_TRANSCEIVER_SEND_ENCODINGS: RTCRtpEncodingParameters = {
	maxBitrate: 2 * 10 ** 6, // in bits per second
	priority: 'medium', // This affects bandwidth and DSCP values on packets (possibly other stuff also, poorly documented)
	networkPriority: 'high', // This is an override for DSCP value set by "priority"
} as any;

// When setting audio networkPriority to high, Chrome use DSCP EF instead of of AF41
export const DEFAULT_AUDIO_TRANSCEIVER_SEND_ENCODINGS: RTCRtpEncodingParameters = {
	priority: 'high', // This affects bandwidth and DSCP values on packets (possibly other stuff also, poorly documented)
	networkPriority: 'high', // This is an override for DSCP value set by "priority"
} as any;

/**
 * Ice servers refresh interval
 */
export const REFRESH_ICE_SERVERS_INTERVAL: number = 3 * 60 * 1000;
