function openFullscreen() {
	const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
		mozRequestFullScreen(): Promise<void>;
		webkitRequestFullscreen(): Promise<void>;
		msRequestFullscreen(): Promise<void>;
	};
	if (docElmWithBrowsersFullScreenFunctions) {
		if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
			return docElmWithBrowsersFullScreenFunctions.requestFullscreen();
		} else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) {
			/* Firefox */
			return docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
		} else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			return docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
		} else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) {
			/* IE/Edge */
			return docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
		}
	}

	return Promise.reject();
}

function closeFullScreen() {
	const docWithBrowsersExitFunctions = document as Document & {
		mozCancelFullScreen(): Promise<void>;
		webkitExitFullscreen(): Promise<void>;
		msExitFullscreen(): Promise<void>;
	};
	if (docWithBrowsersExitFunctions.fullscreenElement) {
		if (docWithBrowsersExitFunctions.exitFullscreen) {
			return docWithBrowsersExitFunctions.exitFullscreen();
		} else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
			/* Firefox */
			return docWithBrowsersExitFunctions.mozCancelFullScreen();
		} else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
			/* Chrome, Safari and Opera */
			return docWithBrowsersExitFunctions.webkitExitFullscreen();
		} else if (docWithBrowsersExitFunctions.msExitFullscreen) {
			/* IE/Edge */
			return docWithBrowsersExitFunctions.msExitFullscreen();
		}
	}

	return Promise.reject();
}

function isInFullscreen() {
	return !!document.fullscreenElement;
}

export { openFullscreen, closeFullScreen, isInFullscreen };
