import { SettingHeaders } from 'hooks/useSettingsController';

export const feedbackNameToSettingHeader = (name: string) => {
	switch (name) {
		case 'sessionEnd':
			return SettingHeaders.FEEDBACK_SESSION_END;
		case 'GoBeSwitch':
			return SettingHeaders.FEEDBACK_SWITCH;
	}
	return null;
};
