import React from 'react';
import './index.scss';

type Props = {
	innerRef: React.LegacyRef<HTMLTextAreaElement> | undefined;
	value: string | number | readonly string[] | undefined;
	onChange: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
	placeholder?: string;
	disabled?: boolean;
	maxLength?: number;
	style?: React.CSSProperties;
};

function AutoSizeTextarea({
	innerRef,
	value,
	onChange,
	placeholder,
	disabled,
	maxLength = 500,
	style,
}: Props) {
	return (
		<div className="autoSizeTextareaWrapper">
			<textarea
				className="autoSizeTextarea"
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				autoComplete="off"
				ref={innerRef}
				disabled={disabled}
				maxLength={maxLength}
				style={style}
			/>
		</div>
	);
}

export default React.memo(AutoSizeTextarea);
